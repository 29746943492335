import Layout from "@inmagik/react-drawers"
import Header from "./Header"
import Menu from "./Menu"

type StandardLayoutProps = {
  className?: string
  classNameTop?: string
  classNameLeft?: string
  classNameRight?: string
  classNameContent?: string
  children: React.ReactNode
}

export default function StandardLayout({
  className = "classname-applied-to-content",
  classNameTop,
  classNameLeft,
  classNameRight = "bg-white h-100 border p-2",
  classNameContent: contentEntries,
  children,
}: StandardLayoutProps) {
  const classNameContent = `bg-white h-100 overflow-auto with-sticky-footer ${contentEntries} `
  return (
    <Layout className={className}>
      <Layout.Top className={classNameTop}>
        <Header />
      </Layout.Top>
      <Layout.Left className={classNameLeft}>
        <Menu />
      </Layout.Left>
      <Layout.Right className={classNameRight}></Layout.Right>
      <Layout.Content className={classNameContent}>{children}</Layout.Content>
    </Layout>
  )
}
