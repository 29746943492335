import { useMemo } from "react"
import { Typeahead } from "react-bootstrap-typeahead"
import "react-bootstrap-typeahead/css/Typeahead.css"
import { Scheda } from "../../types"
import { useSchedaList } from "./scheda"

type SchedaSelectorProps = {
  onChange: (values: Scheda[]) => void
  containerClassName?: string
  labelClassName?: string
  selectorClassName?: string
  labelText?: string
  multiple?: boolean
  clearButton?: boolean
  currentFilter: string | string[]
}

const SchedaSelector = ({
  onChange,
  containerClassName = "d-flex align-items-center",
  labelClassName = "col-sm-4 col-form-label-sm",
  selectorClassName = "col-sm-8",
  labelText = "Scheda servizi",
  multiple = false,
  clearButton = false,
  currentFilter,
}: SchedaSelectorProps) => {
  const schedeServizi = useSchedaList()

  const currentSelected = useMemo(() => {
    if (currentFilter && schedeServizi) {
      const selectedIds = Array.isArray(currentFilter) ? currentFilter : [currentFilter]
      return schedeServizi.data?.results.filter((item) => selectedIds.indexOf(item.id.toString()) !== -1)
    } else {
      return []
    }
  }, [currentFilter, schedeServizi])

  return (
    <div className={containerClassName}>
      <label className={labelClassName}>{labelText}</label>
      <Typeahead
        positionFixed
        paginate
        placeholder="Ricerca scheda"
        id={"SchedaServiziSelector"}
        multiple={multiple}
        clearButton={clearButton}
        isLoading={schedeServizi.isLoading}
        labelKey={(option) => `${(option as Scheda).codice_scheda} ${(option as Scheda).titolo}`}
        options={schedeServizi.data?.results || []}
        selected={currentSelected}
        onChange={(values) => {
          onChange(values as Scheda[])
        }}
        className={selectorClassName}
        size={"sm"}
      />
    </div>
  )
}

export default SchedaSelector
