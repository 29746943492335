import Map from "ol/Map"
import View from "ol/View"
import { get, transform } from "ol/proj"
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react"
import { OlMapContext } from "./context/olMapContext"

type Props = {
  children: React.ReactNode
}

export type OlMapRef = {
  getMap: () => Map | undefined
}

export const OlMap = forwardRef<OlMapRef, Props>((props, ref) => {
  const [contextValue, setContextValue] = React.useState<{ olMap: Map } | null>(null)
  const targetRef = useRef<HTMLDivElement>(null)
  const protectRef = useRef(false)

  useImperativeHandle(
    ref,
    () => ({
      getMap: () => contextValue?.olMap,
    }),
    [contextValue?.olMap]
  )

  useEffect(() => {
    if (targetRef.current && !protectRef.current) {
      protectRef.current = true
      const p4326 = get("EPSG:4326")!
      const p3857 = get("EPSG:3857")!
      const c = [9.19, 45.46]
      const c2 = transform(c, p4326, p3857)

      const view = new View({
        center: c2,
        zoom: 12,
      })
      console.log(view)

      const olMap = new Map({
        target: targetRef.current,
        view: view,
      })

      const newContextValue = {
        olMap,
      }
      setContextValue(newContextValue)
    }
  }, [])

  return (
    <div ref={targetRef} style={{ height: "100%", width: "100%" }}>
      {contextValue && <OlMapContext.Provider value={contextValue}>{props.children}</OlMapContext.Provider>}
    </div>
  )
})

export default OlMap
