import { useDetail, useList } from "@inmagik/react-crud"
import { API_URL } from "../constants"
import { RegolaConsegna } from "../types"

export function useDocumentiPeriodoList(params: Record<string, string | string[] | number[] | number> = {}) {
  return useList<RegolaConsegna>(`${API_URL}/regole-consegna/documenti-periodo`, { query: params })
}

export function useDocumentiPeriodo(id: string) {
  return useDetail<RegolaConsegna>(`${API_URL}/regole-consegna/documenti-periodo`, id)
}
