import { Field, Form, Formik } from "formik"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useCallback, useState } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { useAuth } from "../../auth"
import { FileField } from "../../components/Fields/FileField"
import GenericFormError from "../../components/Fields/GenericFormError"
import { transformErrorForForm } from "../../components/Fields/utils"
import ModalConfirm from "../../components/ModalConfirm"
import StandardLayout from "../../components/StandardLayout"
import { mkStringfromDate } from "../../helper"
import { useConfermaNonEseguito, useUploadVNE } from "../../hooks/catalogoservizi"
import { useDeleteNonEseguito, useNonEseguitoPlainList } from "../../hooks/noneseguito"
import { NonEseguito, Scheda } from "../../types"
import HeaderServizi from "./HeaderServizi"

const filtroNonEseguitoBozza = { bozza: 1 }
const filtroNonEseguitoAttivi = { bozza: 0 }

type Props = {
  schedaServizio: Scheda
}

export default function CaricamentoVNE({ schedaServizio }: Props) {
  const { user } = useAuth()
  const idServizio = parseInt(useParams().idServizio!)
  const servizio = schedaServizio.servizi!.find((ser) => ser.id === idServizio)!
  const [isModalSubmitting, setIsModalSubmitting] = useState(false)

  const nonEseguitoBozza = useNonEseguitoPlainList(servizio.id, filtroNonEseguitoBozza)
  const nonEseguito = useNonEseguitoPlainList(servizio.id, filtroNonEseguitoAttivi)
  const { mutateAsync: deleteNonEseguito } = useDeleteNonEseguito(idServizio)

  const [deleteModalState, deleteModalActions] = useModalTrigger<NonEseguito>()
  const [deleteBulkModalState, deleteBulkModalActions] = useModalTrigger()
  const [uploadModalState, uploadModalActions] = useModalTrigger()

  const refetch = () => {
    nonEseguito.refetch()
    nonEseguitoBozza.refetch()
  }

  const confirmDeleteNonEseguito = useCallback(
    (data?: string) => {
      setIsModalSubmitting(true)
      return deleteNonEseguito(data)
        .then(() => {
          deleteModalActions.close()
          nonEseguitoBozza.refetch()
        })
        .finally(() => {
          setIsModalSubmitting(false)
        })
    },
    [deleteModalActions, deleteNonEseguito, nonEseguitoBozza]
  )

  return (
    <StandardLayout>
      {schedaServizio && servizio && (
        <>
          <HeaderServizi schedaServizio={schedaServizio} servizio={servizio} open="vne" />
          <div className="px-3 pb-3">
            {!user?.is_operatore_comune && (
              <>
                <div className="">
                  <div className="page-title">Non eseguito in bozza</div>

                  {nonEseguitoBozza && nonEseguitoBozza.data?.length === 0 && (
                    <p>Nessun record non eseguito in bozza</p>
                  )}

                  {nonEseguitoBozza.data && (
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Quantità tot</th>
                          <th>Numero records</th>
                          <th>Azioni</th>
                        </tr>
                      </thead>
                      <tbody>
                        {nonEseguitoBozza.data?.map((d: NonEseguito, i) => (
                          <tr key={i}>
                            <td>{mkStringfromDate(d.data, "YYYY-MM-DD")}</td>
                            <td>{d.quantita}</td>
                            <td>{d.numero_records}</td>
                            <td>
                              <button className="btn btn-warning btn-sm" onClick={() => deleteModalActions.open(d)}>
                                {"Elimina data"}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}

                  <div>
                    {nonEseguitoBozza.data && nonEseguitoBozza.data?.length > 0 ? (
                      <button
                        className="btn btn-sm btn-warning me-2"
                        onClick={() => deleteBulkModalActions.open("dummy")}
                      >
                        {"Elimina tutte le bozze"}
                      </button>
                    ) : (
                      <div></div>
                    )}
                    <button className="btn btn-sm btn-primary me-2" onClick={() => uploadModalActions.open("dummy")}>
                      Carica bozza
                    </button>
                  </div>
                </div>

                <hr />
              </>
            )}

            {deleteModalState.isOpen && deleteModalState.value && (
              <ModalConfirm
                isOpen={deleteModalState.isOpen}
                toggle={deleteModalActions.toggle}
                onClosed={deleteModalActions.onClosed}
                title={`Elimina non lavorazioni per la data ${mkStringfromDate(
                  deleteModalState.value.data,
                  "YYYY-MM-DD"
                )}`}
                body={`Confermi l'eliminazione del piano in bozza?`}
                okCallBack={() =>
                  confirmDeleteNonEseguito(mkStringfromDate(deleteModalState.value?.data, "YYYY-MM-DD"))
                }
                isSubmitting={isModalSubmitting}
              />
            )}
            {deleteBulkModalState.value && (
              <ModalConfirm
                isOpen={deleteBulkModalState.isOpen}
                toggle={deleteBulkModalActions.toggle}
                onClosed={deleteBulkModalActions.onClosed}
                title="Conferma caricamento non eseguito"
                body={"Confermi eliminazione dei record di non lavorato in bozza?"}
                isSubmitting={isModalSubmitting}
                okCallBack={() => {
                  confirmDeleteNonEseguito()
                  deleteBulkModalActions.close()
                }}
              />
            )}

            {nonEseguitoBozza.data && nonEseguitoBozza.data?.length > 0 && (
              <>
                <ModificaVNEForm
                  idServizio={servizio.id}
                  nonEseguitoBozza={nonEseguitoBozza.data}
                  refetch={refetch}
                ></ModificaVNEForm>
                <hr />
              </>
            )}

            {servizio && (
              <div className="">
                <div className="page-title">Non eseguito consolidato</div>
                {nonEseguito && nonEseguito.data?.length === 0 && <p>Nessun record non eseguito</p>}

                {nonEseguito.data && (
                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Quantità tot</th>
                        <th>Numero records</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nonEseguito.data?.map((d, i) => (
                        <tr key={i}>
                          <td>{mkStringfromDate(d.data, "YYYY-MM-DD")}</td>
                          <td>{d.quantita}</td>
                          <td>{d.numero_records}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {uploadModalState.value && (
        <UploadForm
          isOpen={uploadModalState.isOpen}
          toggle={uploadModalActions.toggle}
          onClosed={uploadModalActions.onClosed}
          idServizio={servizio.id}
          onUpload={refetch}
        />
      )}
    </StandardLayout>
  )
}

type ModificaVNEProps = {
  idServizio: number
  nonEseguitoBozza: NonEseguito[]
  refetch: () => void
}

const ModificaVNEForm = ({ idServizio, nonEseguitoBozza, refetch }: ModificaVNEProps) => {
  const [confirmModalState, confirmModalActions] = useModalTrigger()
  const { mutateAsync: updateVNE } = useConfermaNonEseguito(idServizio)

  return (
    <Formik
      initialValues={{}}
      onSubmit={(_values, actions) => {
        return updateVNE()
          .catch((err) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            actions.setErrors(transformErrorForForm(err) as any)
          })
          .then(() => {
            refetch()
            confirmModalActions.toggle()
          })
      }}
    >
      {({ errors, isSubmitting, submitForm }) => (
        <Form>
          <GenericFormError errors={errors}></GenericFormError>
          <div>
            {isSubmitting && <Spinner variant="primary"/>}
            {!isSubmitting && (
              <button
                type="button"
                className="btn-sm btn btn-danger"
                disabled={!nonEseguitoBozza || !nonEseguitoBozza.length}
                onClick={() => confirmModalActions.open()}
              >
                {"Conferma caricamento non eseguito"}
              </button>
            )}
          </div>
          {confirmModalState.isOpen && (
            <ModalConfirm
              isOpen={confirmModalState.isOpen}
              toggle={confirmModalActions.toggle}
              onClosed={confirmModalActions.onClosed}
              title="Conferma caricamento non eseguito"
              body={"Confermi il caricamento dei record in bozza?"}
              okCallBack={submitForm}
              isSubmitting={isSubmitting}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}

type UploadFormProps = {
  isOpen: boolean
  toggle?: () => void
  onClosed: () => void
  idServizio: number
  onUpload?: () => void
}

const UploadForm = ({ isOpen, toggle, onClosed, idServizio, onUpload }: UploadFormProps) => {
  const { mutateAsync: uploadVNE } = useUploadVNE(idServizio)

  return (
    <Formik
      initialValues={{ csv_file: undefined }}
      onSubmit={(values, actions) => {
        return uploadVNE(values)
          .then(() => {
            toggle?.()
            onUpload?.()
          })
          .catch((err) => {
            console.error(err)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            actions.setErrors(transformErrorForForm(err) as any)
          })
      }}
    >
      {({ handleSubmit, errors, isSubmitting }) => (
        <Modal show={isOpen} onHide={toggle} onExited={onClosed}>
          <Form onSubmit={handleSubmit}>
            <ModalHeader className="flex-row justify-content-between bg-primary text-white">
              <span>Caricamento VNE</span>
            </ModalHeader>
            <ModalBody>
              <Field required component={FileField} name="csv_file" type="file" accept=".csv"></Field>
              <GenericFormError errors={errors} badRequestError="Tracciato non valido"></GenericFormError>
            </ModalBody>
            <ModalFooter>
              {isSubmitting && <Spinner variant="primary"/>}
              {!isSubmitting && (
                <button className="btn btn-primary" type="submit">
                  Carica
                </button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}
