import dayjs from "dayjs"
import { Button } from "react-bootstrap"
import { Link, useNavigate } from "react-router-dom"
import PropertyList from "../../components/PropertyList"
import StandardLayout from "../../components/StandardLayout"
import { mkStringfromDate } from "../../helper"
import { Scheda } from "../../types"

function getClassificazioneLink(schedaServizio: Scheda) {
  const classificazione = schedaServizio.classificazione
  if (!classificazione) {
    return ""
  }
  return (
    <Link to={`/classificazioni/${classificazione}?scheda=${schedaServizio.id}`}>
      {schedaServizio.classificazione_nome}
    </Link>
  )
}

type Props = {
  schedaServizio: Scheda
}

function getDettagliPianificazione(schedaServizio: Scheda) {
  if (!schedaServizio) {
    return
  }
  return (
    <>
      {schedaServizio.pianificazione === "pds" && (
        <span>
          {schedaServizio.pds_richiesto_giorno && <span>Giorno settimana</span>}
          {schedaServizio.pds_richiesta_data && <span>Data</span>}
          {schedaServizio.pds_richiesta_ora && <span> + fascia oraria. </span>}
        </span>
      )}
    </>
  )
}

function getDettaglioElementoGeo(schedaServizio: Scheda) {
  return (
    <>
      {schedaServizio.tipo_oggetto}
      {schedaServizio.pds_richiesto_oggetto && <span> (obbligatorio)</span>}
    </>
  )
}

function getFrequenzaServizio(schedaServizio: Scheda) {
  if (schedaServizio.classificazione) {
    return "Vedi classificazione"
  }
  return `${schedaServizio.frequenza_numero_default} / ${schedaServizio.frequenza_periodo_default}`
}

function getSla(schedaServizio: Scheda) {
  if (schedaServizio.classificazione) {
    return "Vedi classificazione"
  }
  return `${schedaServizio.tempo_recupero_sla_ore_default} ore`
}

export default function SchedaServizioDettaglio({ schedaServizio }: Props) {
  const navigate = useNavigate()
  return (
    <StandardLayout>
      <div className="d-flex justify-content-between sticky-header shadow-sm p-3">
        {schedaServizio && (
          <div className="page-title">
            {schedaServizio.titolo} - {schedaServizio.codice_scheda}
          </div>
        )}
        <Button
          size="sm"
          onClick={() => navigate(`/overview/pianificato/${schedaServizio.id}/${dayjs().format("YYYY-MM-DD")}`)}
        >
          Overview
        </Button>
      </div>
      {schedaServizio && (
        <div className="p-3">
          <div className="mt-2">
            <PropertyList
              propLists={[
                [
                  ["ID", schedaServizio.id],
                  ["Descrizione", schedaServizio.descrizione],
                  ["Codice scheda", schedaServizio.codice_scheda],
                  ["Codice macroscheda Comune", schedaServizio.codice_macroscheda_comune],
                  ["Inizio validità", mkStringfromDate(schedaServizio.inizio_val, "YYYY-MM-DD")],
                  ["Tipo servizio", `${schedaServizio.tipo_servizio} (${schedaServizio.tipo_servizio_label})`],
                  ["Periodicità report", schedaServizio.periodicita_report_label],
                ],
                [
                  ["Unità di misura", schedaServizio.um],
                  ["Tipo oggetto", getDettaglioElementoGeo(schedaServizio)],
                  ["Programmazione", schedaServizio.pianificazione],
                  ["Dettagli programmazione", getDettagliPianificazione(schedaServizio)],
                  ["Frequenza servizio", getFrequenzaServizio(schedaServizio)],
                  ["SLA", getSla(schedaServizio)],
                  ["Classificazione oggetti", getClassificazioneLink(schedaServizio)],
                ],
              ]}
            ></PropertyList>

            <h4 className="mt-3">Servizi</h4>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Codice servizio</th>
                  <th>Descrizione</th>
                  <th>Inizio validità</th>
                  <th>Fine validità</th>
                  <th>Gestore</th>
                </tr>
              </thead>
              <tbody>
                {schedaServizio.servizi?.map((servizio) => (
                  <tr key={servizio.id}>
                    <td>
                      <Link to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}`}>
                        {servizio.codice_servizio}
                      </Link>
                    </td>

                    <td>{servizio.descrizione}</td>
                    <td>{mkStringfromDate(servizio.inizio_val, "YYYY-MM-DD")}</td>
                    <td>{mkStringfromDate(servizio.fine_val, "YYYY-MM-DD")}</td>
                    {servizio.gestore_data ? <td>{servizio.gestore_data.codice}</td> : <td></td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </StandardLayout>
  )
}
