import { useState } from "react"
import { Button } from "react-bootstrap"
import { fetchApi } from "@inmagik/react-crud"
import { Field, Formik, getIn } from "formik"
import * as yup from "yup"
import { API_URL } from "../constants"
import Footer from "../components/Footer"
import Header from "../components/Header"

const schema = yup.object().shape({
  email: yup.string().email().required().label("Email"),
})

const initialValues = {
  email: "",
}

export function PasswordDimenticata() {
  const [confirm, setConfirm] = useState(false)
  return (
    <div>
      <Header />
      <div className="mt-4">
        <div className="row no-gutters">
          <div className="col-md-4 offset-md-4">
            <div className="text-center">
              <div className="page-title">PASSWORD DIMENTICATA</div>
              <p>Inserisci la tua email</p>
            </div>
            <Formik
              validationSchema={schema}
              initialValues={initialValues}
              enableReinitialize
              onSubmit={async (values) => {
                await fetchApi(API_URL + "/utenti/users/recover_password/", {
                  method: "POST",
                  body: JSON.stringify(values),
                  headers: {
                    "Content-Type": "application/json",
                  },
                }).then(() => {
                  setConfirm(true)
                })
              }}
            >
              {({ handleSubmit, touched, errors }) => (
                <form onSubmit={handleSubmit} className="p-2 mt-2">
                  <div className="form-group">
                    <Field
                      name="email"
                      type="text"
                      placeholder="email"
                      className={
                        "form-control" + (getIn(errors, "email") && getIn(touched, "email") ? " is-invalid" : "")
                      }
                    />
                    {getIn(errors, "email") && getIn(touched, "email") && (
                      <div className="text-danger">{getIn(errors, "email")}</div>
                    )}
                  </div>
                  {confirm && (
                    <div className="alert alert-success mt-2 py-1">
                      Ti è stata inviata una email con le istruzioni per recuperare la password.
                    </div>
                  )}
                  <Button type="submit" color="primary" className="w-100 mt-2 text-white">
                    Recupera password
                  </Button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
