import { Link } from "react-router-dom"
import Paginator from "../../components/Paginator"
import Search from "../../components/Search"
import StandardLayout from "../../components/StandardLayout"
import { removeEmpties } from "../../helper"
import { useClassificazioniList } from "../../hooks/classificazioni"
import { useQsFilters } from "../../hooks/useFilters"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  search: params.get("search") ?? "",
})

const Classificazioni = () => {
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)
  const classificazioni = useClassificazioniList(
    removeEmpties({
      search: filters.search,
      page: String(filters.page),
    })
  )

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="d-flex justify-content-between">
        <div className="page-title">Classificazioni</div>
          <Search
            value={uiFilters.search}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setFiltersDebounced({ search: e.currentTarget.value, page: 1 })
            }
          />
        </div>
      </div>

      <div className="p-3">
        {classificazioni.data ? (
          <div>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Descrizione</th>
                  <th>Tipo oggetto</th>
                </tr>
              </thead>
              <tbody>
                {classificazioni.data.results.map((classificazione) => (
                  <tr key={classificazione.id}>
                    <td>
                      <Link to={`/classificazioni/${classificazione.id}`}>{classificazione.nome}</Link>
                    </td>
                    <td>{classificazione.descrizione}</td>
                    <td>{classificazione.tipo_oggetto}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      <div className="sticky-footer bg-light border-top">
        <Paginator
          count={classificazioni?.data?.count ?? 0}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </StandardLayout>
  )
}

export default Classificazioni
