import Layout from "@inmagik/react-drawers"
import { IoMenu } from "react-icons/io5"
import { NavLink, useNavigate } from "react-router-dom"
import { useAuth } from "../auth"
import { useLoggedIn } from "../contexts/LoggedInContext"

function Header() {
  const { user, performLogout } = useAuth()
  const navigate = useNavigate()

  const { domainData } = useLoggedIn()
  const environment = domainData?.server_info?.environment

  return (
    <nav className="navbar navbar-expand sticky-top navbar-dark bg-primary">
      <div className="container-fluid">
        {/* <span to="/" className="navbar-brand d-flex flex-row align-items-center"> */}
        <span className="navbar-brand d-flex flex-row align-items-center">
          <Layout.Switch left>
            {({ toggle }) => <IoMenu onClick={() => toggle()} className="text-white m-2 pointer" size={24} />}
          </Layout.Switch>
          {"GreenGO"}
          {environment && <small className="ms-2 badge badge-sm bg-dark">{environment}</small>}
        </span>
        <ul className="navbar-nav ml-auto">
          {user !== null && (
            <>
              <li className="nav-item">
                <NavLink to="/profile" className="nav-link d-flex align-items-center">
                  {user.full_name}{" "}
                  {!user.operatore?.gestore && <span className="ms-2 badge bg-dark">Comune di Milano</span>}
                  {user.operatore?.gestore && (
                    <span className="ms-2 badge bg-warning">{user.operatore?.gestore.codice} </span>
                  )}
                </NavLink>
              </li>
            </>
          )}
          {user !== null && (
            <li className="nav-item me-2">
              <a
                onClick={(e) => {
                  e.preventDefault()
                  navigate("/")
                  performLogout()
                }}
                className="nav-link"
                href="#logout"
              >
                Logout
              </a>
            </li>
          )}
        </ul>
      </div>
    </nav>
  )
}

export default Header
