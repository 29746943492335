import { useDetail, useList } from "@inmagik/react-crud"
import { API_URL } from "../constants"
import { Contenitore } from "../types"

export function useContenitoriList(params: Record<string, string | string[] | number[] | number> = {}) {
  return useList<Contenitore>(`${API_URL}/contenitori`, { query: params })
}

export function useContenitore(id: string) {
  return useDetail<Contenitore>(`${API_URL}/contenitori`, id)
}
