import { Feature } from "ol"
import { Geometry } from "ol/geom"
import OlVectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import { StyleLike } from "ol/style/Style"
import { FlatStyleLike } from "ol/style/flat"
import React, { useEffect, useRef, useState } from "react"
import { VectorLayerContext } from "./context/olLayerContext"
import { useOlMap } from "./context/useOlMap"

type Props = {
  children: React.ReactNode
  source: VectorSource
  style?: StyleLike | FlatStyleLike | null
  zIndex?: number
}

export function VectorLayer(props: Props) {
  const olMap = useOlMap()
  const [contextValue, setContextValue] = useState<{ olLayer: OlVectorLayer<VectorSource<Feature<Geometry>>> } | null>(
    null
  )
  const protectRef = useRef(false)

  useEffect(() => {
    if (olMap) {
      const layer = contextValue?.olLayer
      if (!protectRef.current) {
        protectRef.current = true
        console.log("ALLOCATE VECTOR LAYER")
        const layer = new OlVectorLayer({
          source: props.source,
          style: props.style,
          zIndex: props.zIndex ?? 0,
        })
        olMap.addLayer(layer)
        const newContextValue = {
          olLayer: layer,
        }
        setContextValue(newContextValue)
      } else {
        if (props.source !== layer?.getSource()) {
          console.log("SOURCE CHANGED")
          layer?.setSource(props.source)
        }
        if (props.style !== layer?.getStyle()) {
          console.log("STYLE CHANGED")
          layer?.setStyle(props.style)
        }
      }
    }
  }, [contextValue?.olLayer, olMap, props.source, props.style, props.zIndex])

  return (
    <>
      {contextValue && <VectorLayerContext.Provider value={contextValue}>{props.children}</VectorLayerContext.Provider>}
    </>
  )
}
