/* eslint-disable @typescript-eslint/no-explicit-any */
import isPlainObject from "lodash/isPlainObject"
import mapValues from "lodash/mapValues"

// Convert null -> empty string
export function fixInitialValuesNull<T>(values: T, fields?: string[], maxDepth = Infinity): T {
  if (maxDepth === 0) {
    return values
  }
  if (!values || !isPlainObject(values)) {
    return values
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ctx: any = values
  return (fields || Object.keys(values)).reduce<T>((fixedValues, field) => {
    let fixedValue
    const fieldValue = ctx[field]
    if (isPlainObject(fieldValue)) {
      fixedValue = fixInitialValuesNull(fieldValue, fields, maxDepth - 1)
    } else if (Array.isArray(fieldValue)) {
      fixedValue = fieldValue.map((value) => fixInitialValuesNull(value, fields, maxDepth - 1))
    } else {
      fixedValue = fieldValue === null ? "" : fieldValue
    }
    return {
      ...fixedValues,
      [field]: fixedValue,
    }
  }, values)
}

function arrayze<T>(arg: T | T[]): T[] {
  if (Array.isArray(arg)) {
    return arg
  }
  return [arg]
}

// Convert empty string -> null
export function setNullOnEmptyValues<T>(values: T, fields: string[], maxDepth = Infinity): T {
  if (maxDepth === 0) {
    return values
  }
  if (!values || !isPlainObject(values)) {
    return values
  }
  const ctx: any = values
  return (fields || Object.keys(values)).reduce<T>((fixedValues, field) => {
    let fixedValue
    const fieldValue = ctx[field]
    if (isPlainObject(fieldValue)) {
      fixedValue = setNullOnEmptyValues(fieldValue, fields, maxDepth - 1)
    } else if (Array.isArray(fieldValue)) {
      fixedValue = fieldValue.map((value) => setNullOnEmptyValues(value, fields, maxDepth - 1))
    } else {
      fixedValue = fieldValue === "" ? null : fieldValue
    }
    return {
      ...fixedValues,
      [field]: fixedValue,
    }
  }, values)
}

export function transformErrorForForm(error: any) {
  if (error.response.status === 400 && isPlainObject(error.data)) {
    // TODO: Better joins of errors...
    return mapValues(error.data, (listOfErrors) => arrayze(listOfErrors).join(","))
  }
  // When no 400 (not related 2 form)
  // add a special __noFieldsServerError key \w original error
  return {
    __noFieldsServerError: {...error.data, status: error.response.status},
  }
}
