import { useData, useDetail, useList } from "@inmagik/react-crud"
import { API_URL } from "../constants"
import { Area, Dominio } from "../types"

export function useAreeList(params: Record<string, string | string[] | number[] | number> = {}) {
  return useList<Area>(`${API_URL}/aree`, { query: params })
}

export function useArea(id: string) {
  return useDetail<Area>(`${API_URL}/aree`, id)
}

export function useDominio() {
  return useData<Dominio>(`${API_URL}/dominio-datibase`)
}
