import { ReactElement } from "react"
import { MdAccessTime, MdDashboard, MdHome, MdInsertChart, MdPeople, MdStorage, MdWork } from "react-icons/md"
import { NavLink } from "react-router-dom"
import { useAuth } from "../auth"

const version = import.meta.env.VITE_APP_VERSION || 'dev. version'

interface PropsType {
  to: string
  icon: ReactElement
  children: string
}

const MenuItem = ({ to, icon, children }: PropsType) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? "bg-selected list-group-item bg-primary text-white d-flex align-items-center justify-content-between py-3"
          : "list-group-item bg-primary text-white d-flex align-items-center justify-content-between py-3"
      }
    >
      {children}
      {icon}
    </NavLink>
  )
}

export default function Menu() {
  const { user } = useAuth()
  return (
    <div className="w-100 bg-primary text-white h-100 d-flex flex-column justify-content-between">
      <ul className="list-group list-group-flush">
        {/* <MenuItem to="/" icon={<MdHome size="20" />} exact>
          Home
        </MenuItem> */}
        <MenuItem to="/" icon={<MdHome size="24" />}>
          Calendario
        </MenuItem>
        <MenuItem to="/catalogo-servizi" icon={<MdStorage size="24" />}>
          Catalogo servizi
        </MenuItem>
        <MenuItem to="/dati-base" icon={<MdDashboard size="24" />}>
          Dati di base
        </MenuItem>
        <MenuItem to="/classificazioni" icon={<MdDashboard size="24" />}>
          Classificazioni
        </MenuItem>
        <MenuItem to="/programmazioni" icon={<MdAccessTime size="24" />}>
          Programmi dei servizi
        </MenuItem>
        <MenuItem to="/non-eseguito" icon={<MdWork size="24" />}>
          Non Eseguito
        </MenuItem>
        {/* <MenuItem to="/navigatore-cartografico" icon={<MdMap size="24" />}>
          Navigatore cartografico
        </MenuItem> */}
        <MenuItem to="/reports" icon={<MdInsertChart size="24" />}>
          Reports
        </MenuItem>
        <MenuItem to="/documenti" icon={<MdInsertChart size="24" />}>
          Archivio documenti
        </MenuItem>
        <MenuItem to="/regole-consegna" icon={<MdInsertChart size="24" />}>
          Regole consegna
        </MenuItem>
        <MenuItem to="/documenti-periodo" icon={<MdInsertChart size="24" />}>
          Consegne documenti
        </MenuItem>
        {!user?.operatore.gestore && (
          <MenuItem to="/utenti" icon={<MdPeople size="24" />}>
            Gestione Utenti
          </MenuItem>
        )}
      </ul>
      {version &&<div className="p-2">
        <small>{version}</small>
      </div>}
    </div>
  )
}
