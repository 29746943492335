import { Field, getIn, useFormikContext } from "formik"
import { useMemo } from "react"

interface FieldProps {
  name: string
  required?: boolean
  label: string
  as?: string
  type?: string
  component?: any
  children?: any
  value?: any
  disabled?: boolean
}

export function VField({ name, label, as, type, component, children, required = false, disabled = false }: FieldProps) {
  const { touched, errors } = useFormikContext()

  const className = useMemo(() => {
    if (as === "select") {
      return getIn(errors, name) && getIn(touched, name) ? "is-invalid form-select" : "form-select"
    }
    if (type === "checkbox") {
      return getIn(errors, name) && getIn(touched, name) ? "is-invalid form-check-input" : "form-check-input"
    }
    return getIn(errors, name) && getIn(touched, name) ? "is-invalid form-control" : "form-control"
  }, [as, type, errors, touched, name])

  return (
    <div className="form-group mb-3">
      <label className="label-m label-field">
        {label} {required && <span className="text-danger ms-1">*</span>}
      </label>
      <div className="text-danger error-field">
        {getIn(errors, name) && getIn(touched, name) && getIn(errors, name)}
      </div>
      <div>
        <Field name={name} className={className} as={as} type={type} component={component} disabled={disabled}>
          {children}
        </Field>
      </div>
    </div>
  )
}
