import { Spinner } from "react-bootstrap"
import { Route, Routes, useParams } from "react-router-dom"
import StandardLayout from "../../components/StandardLayout"
import { useDettaglioClassificazione } from "../../hooks/classificazioni"
import { useQsFilters } from "../../hooks/useFilters"
import ClassificazioneDettaglio from "./ClassificazioneDettaglio"
import ClassificazioneMap from "./ClassificazioneMap"
import ClassificazioneList from "./ClassificazioniList"

const initFilters = (params: URLSearchParams) => ({
  scheda: params.get("scheda") ?? "",
})

export function ClassificazioneRoutes() {
  const { filters } = useQsFilters(initFilters)
  const classificazioneId = useParams().id
  const scheda = useParams().scheda ?? filters.scheda
  const { data: classificazione } = useDettaglioClassificazione(classificazioneId!.toString(), scheda)

  if (!classificazione) {
    return (
      <StandardLayout>
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          <Spinner variant="primary" />
        </div>
      </StandardLayout>
    )
  }

  return (
    <Routes>
      <Route index element={<ClassificazioneDettaglio classificazione={classificazione} />} />
      <Route path="/list" element={<ClassificazioneList classificazione={classificazione} />} />
      <Route path="/map" element={<ClassificazioneMap classificazione={classificazione} />} />
    </Routes>
  )
}
