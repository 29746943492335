import { useContext } from "react"
import { VectorLayerContext } from "./olLayerContext"

export function useOlVectorLayer() {
  const context = useContext(VectorLayerContext)
  if (!context) {
    throw new Error("useOlMap must be used within a OlMapProvider")
  }
  return context.olLayer
}
