import { Route, Routes } from "react-router-dom"
import { AuthLayout } from "./components/AuthLayout"
import { GuestLayout } from "./components/GuestLayout"
import { AttivazioneAccount } from "./pages/AttivazioneAccount"
import Calendar from "./pages/Calendar"
import CatalogoServizi from "./pages/CatalogoServizi"
import { SchedaServizioRoutes } from "./pages/CatalogoServizi/SchedaServizioRoutes"
import { ClassificazioneRoutes } from "./pages/Classificazioni/ClassificazioneRoutes"
import Classificazioni from "./pages/Classificazioni/Classificazioni"
import { DatiBaseRoutes } from "./pages/DatiBase/DatiBaseRoutes"
import Documenti from "./pages/Documenti"
import DocumentiPeriodo from "./pages/DocumentiPeriodo"
import Home from "./pages/Home"
import { Login } from "./pages/Login"
import NonEseguito from "./pages/NonEseguito"
import { PasswordDimenticata } from "./pages/PasswordDimenticata"
import Profile from "./pages/Profile"
import ProgrammazioneDettaglio from "./pages/Programmazioni/ProgrammazioneDettaglio"
import Programmazioni from "./pages/Programmazioni/Programmazioni"
import RegoleConsegna from "./pages/RegoleConsegna"
import { ResetPassword } from "./pages/ResetPassword"
import Utenti from "./pages/Utenti"
import Reports from "./pages/Reports"
import ReportDettaglio from "./pages/Reports/ReportDettaglio"
import Overview from "./pages/Overview"

export function Navigation() {
  return (
    <Routes>
      <Route path="/" element={<AuthLayout redirect_to="/login" />}>
        <Route index element={<Calendar />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/calendario" element={<Calendar />} />
        <Route path="/catalogo-servizi" element={<CatalogoServizi />} />
        <Route path="/catalogo-servizi/schede/:id/*" element={<SchedaServizioRoutes />} />
        <Route path="/dati-base/*" element={<DatiBaseRoutes />} />
        <Route path="/classificazioni" element={<Classificazioni />} />
        <Route path="/classificazioni/:id/*" element={<ClassificazioneRoutes />} />
        <Route path="/programmazioni" element={<Programmazioni />} />
        <Route path="/programmazioni/:id" element={<ProgrammazioneDettaglio />} />
        <Route path="/non-eseguito" element={<NonEseguito />} />
        <Route path="/navigatore-cartografico" element={<Profile />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/reports/:id" element={<ReportDettaglio />} />
        <Route path="/documenti" element={<Documenti />} />
        <Route path="/regole-consegna" element={<RegoleConsegna />} />
        <Route path="/documenti-periodo" element={<DocumentiPeriodo />} />
        <Route path="/utenti" element={<Utenti />} />
        <Route path="/overview/*" element={<Overview />} />
      </Route>
      <Route path="/login" element={<GuestLayout redirect_to="/" />}>
        <Route index element={<Login />}></Route>
      </Route>
      <Route path="/password-dimenticata" element={<GuestLayout redirect_to="/" />}>
        <Route index element={<PasswordDimenticata />}></Route>
      </Route>
      <Route path="/reset/:token" element={<GuestLayout redirect_to="/" />}>
        <Route index element={<ResetPassword />}></Route>
      </Route>
      <Route path="/activate/:token" element={<GuestLayout redirect_to="/" />}>
        <Route index element={<AttivazioneAccount />}></Route>
      </Route>
      <Route path="/home/" element={<GuestLayout redirect_to="/" />}>
        <Route index element={<Home />}></Route>
      </Route>
    </Routes>
  )
}
