import { fetchApi } from "@inmagik/react-crud"
import Feature, { FeatureLike } from "ol/Feature"
import { FeatureLoader } from "ol/featureloader"
import GeoJSON from "ol/format/GeoJSON"
import { Geometry } from "ol/geom"
import { all } from "ol/loadingstrategy"
import { unByKey } from "ol/Observable"
import VectorSource from "ol/source/Vector"
import ImageTile from "ol/source/ImageTile"
import { Stroke, Style } from "ol/style"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useOlMap } from "../../ol/context/useOlMap"
import SimpleMap, { OlMapRef } from "../../ol/SimpleMap"
import { TileLayer } from "../../ol/TileLayer"
import { VectorLayer } from "../../ol/VectorLayer"
import { FeatureCollectionVia } from "../../types"
import { useAuth } from "../../auth"
import { Popup } from "../../ol/Popup"
import {  BASE_MAP_TILE_URL } from "../../constants"
import { Spinner } from "react-bootstrap"

const tileSource = new ImageTile({
  url: BASE_MAP_TILE_URL,
})

function FeaturePopup() {
  return (
    <Popup
      trigger="pointermove"
      autoPan
      renderHtml={(feature) => {
        const properties = feature.getProperties()

        return (
          <div className="p-3 bg-white">
            {properties.toponym}
            <br />
            {properties.streetcode}
          </div>
        )
      }}
    />
  )
}

function makeLoader(
  source: VectorSource<Feature<Geometry>>,
  runFunction: (params?: Record<string, string>) => Promise<FeatureCollectionVia>
): FeatureLoader<Feature<Geometry>> {
  return (_extent, _resolution, _projection, success, error) => {
    runFunction()
      .then((resp) => {
        const features = (source.getFormat()?.readFeatures(resp) ?? []) as Feature<Geometry>[]
        source.clear()
        source.addFeatures(features)
        success!(features)
      })
      .catch(() => error!())
  }
}

type OverviewMapProps = {
  url: string
  selectedVia?: number
  onClick: (f: FeatureLike) => void
}

export function OverviewMap({ url, selectedVia, onClick }: OverviewMapProps) {
  const mapRef = useRef<OlMapRef>(null)
  const [isLoading, setIsLoading] = useState(true)
  const { tokens } = useAuth()

  const Oggetti = useCallback(async () => {
    const response = await fetchApi(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokens?.access}`,
      },
      responseType: "text",
    })
    return response
  }, [url, tokens?.access])

  const source = useMemo(() => {
    const s = new VectorSource({
      wrapX: false,
      format: new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }),
      strategy: all,
    })

    const loader = makeLoader(s, Oggetti)
    s.setLoader(loader)

    s.on("featuresloadstart", () => {
      setIsLoading(true)
    })

    s.on("featuresloadend", () => {
      setIsLoading(false)
      const features = source.getFeatures()
      if (features && features.length === 1) {
        var extent = features[0].getGeometry()!.getExtent()?.slice(0)
        if (extent && mapRef.current) {
          mapRef.current.getMap()?.getView()?.fit(extent, { maxZoom: 16 })
        }
      }
    })
    return s
  }, [url, Oggetti])

  return (
    <>
      <SimpleMap ref={mapRef}>
        <TileLayer source={tileSource} />
        <VectorLayer
          zIndex={1}
          source={source}
          style={(feature, zoom) => {
            const props = feature.getProperties()
            const qta = parseFloat(props.quantita || "0.0")
            let color = "#666666"
            if (qta > 0) color = "dodgerblue"
            if (props.streetcode === selectedVia) color = "red"
            return new Style({
              stroke: new Stroke({
                color: color,
                width: Math.max(3.5 - Math.log(zoom), 1.2),
              }),
            })
          }}
        >
          <OnClick onClick={(f) => onClick(f)} />
          <FeaturePopup></FeaturePopup>
        </VectorLayer>
      </SimpleMap>
      {isLoading && (
        <div
          className="position-absolute top-0 left-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="bg-white p-3 shadow-sm d-flex flex-column justify-content-center align-items-center">
            <Spinner animation="border" className="mb-2" />
            Caricamento in corso
          </div>
        </div>
      )}
    </>
  )
}

type OnClickProps = {
  onClick: (f: FeatureLike) => void
}

function OnClick({ onClick }: OnClickProps) {
  const map = useOlMap()

  useEffect(() => {
    const evtKey = map.on("click", (e) => {
      const features = e.target.getFeaturesAtPixel(e.pixel)

      if (features && features.length) {
        const currentFeature = features[0]
        onClick(currentFeature)
      }
    })
    return () => {
      return unByKey(evtKey)
    }
  }, [onClick])

  return null
}
