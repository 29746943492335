import { Link } from "react-router-dom"
import { useAuth } from "../auth"
import classNames from "classnames"
import { useLocation } from "react-router-dom"
import { useState } from "react"

interface MenuLinkProps {
  to: string
  icon?: string
  title: string
}

function MenuLink({ to, title, icon }: MenuLinkProps) {
  const location = useLocation()
  const active = location.pathname.includes(to)

  return (
    <Link
      to={to}
      className={classNames("text-decoration-none my-1 text-white me-3 nav-topbar", {
        "nav-topbar-active": active,
      })}
    >
      <div className="d-flex flex-row">
        <div className="d-flex align-items-center justify-content-center px-2 py-2">
          <div className="material-icons-outlined me-2">{icon}</div>
          <div>{title}</div>
        </div>
      </div>
    </Link>
  )
}

export function NavMenu() {
  const { user, performLogout } = useAuth()
  const [hoverImpostazioni, setHoverImpostazioni] = useState(false)
  const location = useLocation()

  return (
    <div className="d-flex align-items-center justify-content-between w-100 position-fixed left-0 top-0 right-0 topbar">
      <div>
        <Link to="/" className="text-decoration-none">
          <img alt="logo" className="d-block mx-auto" />
        </Link>
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <MenuLink to="/clienti" icon="groups" title="Clienti" />
          <MenuLink to="/bilance" icon="scale" title="Bilance" />
          <MenuLink to="/interventi-calendario" icon="event_note" title="Interventi" />
          <MenuLink to="/esportazioni" icon="list_alt" title="Esportazioni" />
        </div>
        <Link className="no-link" to="/profilo">
          <div
            className={classNames("badge rounded-pill ms-2 d-flex align-items-center px-2", {
              "bg-secondary": location.pathname === "/profilo",
              "text-white": location.pathname === "/profilo",
              "bg-white text-secondary": location.pathname !== "/profilo",
            })}
          >
            <span className="material-icons-outlined">person</span> {user?.full_name}
          </div>
        </Link>
        <div className="ms-5 position-relative pointer" onClick={() => setHoverImpostazioni(!hoverImpostazioni)}>
          <div className="h-100 d-flex align-items-center">
            <span className="material-icons-outlined text-white">settings</span>
          </div>
          {hoverImpostazioni && (
            <div className="position-absolute bg-white p-2 rounded top-100 shadow" style={{ right: 0, top: "100%" }}>
              <Link to="/utenti" className="text-decoration-none text-dark">
                <div className="d-flex align-items-center">
                  <span className="material-icons-outlined me-2">people</span>
                  <span>Utenti</span>
                </div>
              </Link>
              <Link to="/operatori" className="text-decoration-none text-dark">
                <div className="d-flex align-items-center">
                  <span className="material-icons-outlined me-2">people</span>
                  <span>Operatori</span>
                </div>
              </Link>
              <div className="d-flex align-items-center" onClick={performLogout}>
                <span className="material-icons-outlined me-2">logout</span>
                <span>Logout</span>
              </div>
            </div>
          )}
        </div>
        {/* <div className="ms-4 d-flex align-items-center">
          <Link to="/utenti" className="text-decoration-none my-1 text-white">
            <div className="d-flex flex-row">
              <span className="d-flex align-items-center justify-content-center px-2 py-2">
                <span>Utenti</span>
              </span>
            </div>
          </Link>
          <Link
            to="/operatori"
            className="text-decoration-none my-1 text-white"
          >
            <div className="d-flex flex-row">
              <div>
                <span className="d-flex align-items-center justify-content-center px-2 py-2">
                  <span>Operaotori</span>
                </span>
              </div>
            </div>
          </Link>
        </div> */}
      </div>
    </div>
  )
}
