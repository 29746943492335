import { useAction, useDelete, useDetail, useList, usePlainList } from "@inmagik/react-crud"
import { API_URL } from "../constants"
import { Piano, PianoDettaglio } from "../types"

export function usePianiList(id: number, params: Record<string, string | string[] | number | number[]> = {}) {
  return usePlainList<Piano>(`${API_URL}/servizi/${id}/piani`, { query: params })
}

export function useDeletePiano() {
  console.log("delete piano")
  return useDelete(`${API_URL}/piani-servizi`)
}

export function usePianiServiziList(params: Record<string, string | string[] | number | number[]> = {}) {
  return useList<Piano>(`${API_URL}/piani-servizi`, { query: params })
}

export function usePianiServiziDettaglio(id: string) {
  return useDetail<Piano>(`${API_URL}/piani-servizi`, id)
}

export function useDettaglioPianiServizi(
  id: string,
  params: Record<string, string | string[] | number | number[]> = {}
) {
  return useList<PianoDettaglio>(`${API_URL}/piani-servizi/${id}/dettagli`, { query: params })
}

export function useUpdateInizioVal(id: string) {
  return useAction(`${API_URL}/piani-servizi/${id}/modifica-inizio-val/`, {})
}
export function useUpdateFineVal(id: string) {
  return useAction(`${API_URL}/piani-servizi/${id}/modifica-fine-val/`, {})
}

export function useDeletePianoServizio() {
  return useDelete(`${API_URL}/piani-servizi`)
}
