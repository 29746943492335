import OlTileLayer from "ol/layer/Tile"
import TileSource from "ol/source/Tile"
import { useEffect, useRef } from "react"
import { useOlMap } from "./context/useOlMap"

type Props = {
  source: TileSource
  zIndex?: number
}

export function TileLayer(props: Props) {
  const olMap = useOlMap()
  const layerRef = useRef<OlTileLayer<TileSource> | null>(null)

  useEffect(() => {
    if (!layerRef.current) {
      console.log("ALLOCATE TILE LAYER")
      layerRef.current = new OlTileLayer({
        source: props.source,
        zIndex: props.zIndex,
      })
    }
  }, [props.source, props.zIndex])

  useEffect(() => {
    if (olMap) {
      try {
        olMap.addLayer(layerRef.current!)
        console.log("ADDED TILE LAYER")
      } catch (e) {
        // layer already added
      }
    }
  }, [olMap])

  useEffect(() => {
    console.log("TILE LAYER CHANGED SOURCE")
    layerRef.current?.setSource(props.source)
  }, [props.source])

  useEffect(() => {
    if (props.zIndex !== undefined) {
      console.log("TILE LAYER CHANGED ZINDEX")
      layerRef.current?.setZIndex(props.zIndex)
    }
  }, [props.zIndex])

  return null
}
