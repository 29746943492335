import { DataFetchingAuthContext, fetchApi, useList, usePlainList } from "@inmagik/react-crud"
import { useMutation } from "@tanstack/react-query"
import { useContext } from "react"
import { API_URL } from "../constants"
import { NonEseguito } from "../types"

export function useNonEseguitoList(params: Record<string, string | string[] | number | number[]> = {}) {
  return useList<NonEseguito>(`${API_URL}/non-eseguito`, { query: params })
}

export function useNonEseguitoPlainList(
  id: number,
  params: Record<string, string | string[] | number | number[]> = {}
) {
  return usePlainList<NonEseguito>(`${API_URL}/servizi/${id}/non_eseguito`, { query: params })
}

// export function useDeleteNonEseguito(id: number) {
//   return useDelete(`${API_URL}/servizi/${id}/non_eseguito`)
// }

//TODO: DELETE BULK

export function useDeleteNonEseguito(id: number) {
  const authHeaders = useContext(DataFetchingAuthContext)
  const headers = { ...authHeaders, "Content-Type": "application/json" }

  return useMutation({
    mutationFn: (data?: string) => {
      let apiUrl = `${API_URL}/servizi/${id}/non_eseguito/`
      if (data) {
        apiUrl += `?data=${data}`
      }
      return fetchApi(apiUrl, {
        method: "DELETE",
        headers,
      })
    },
  })
}
