import { usePlainList } from "@inmagik/react-crud"
import { API_URL } from "../constants"
import { CalendarEvent } from "../types"

export function usePlainEventList(params: Record<string, string | string[] | number[] | number> = {}) {
  return usePlainList<CalendarEvent>(`${API_URL}/calendario-schede`, { query: params })
}
// export function usePlainEventList(params: Record<string, string | string[] | number[]> = {}) {
//   return usePlainList<Evento>(`${API_URL}/calendario`, { query: params })
// }
