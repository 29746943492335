import { FieldProps, getIn } from "formik"
import { Form } from "react-bootstrap"

interface InputFieldProps extends FieldProps {
  label: string
  textarea: boolean
  addonText: string
  rawLayout: boolean
  labelClassName: string
  formGroupClassName: string
  frequenza_attesa_periodo: string
  readOnly: boolean
  rowLayout?: boolean
}

export const InputField = ({
  field,
  form: { errors },
  label,
  textarea = false,
  rowLayout = false,
  labelClassName = "",
  formGroupClassName = "",
  readOnly = false,
}: InputFieldProps) => {
  const error = getIn(errors, field.name)

  return (
    <Form.Group className={formGroupClassName} controlId={`${label}-control-input`}>
      {label && (
        <Form.Label size={rowLayout ? 4 : "sm"} className={`text-left ${labelClassName}`}>
          {label}
        </Form.Label>
      )}
      {!textarea && <Form.Control readOnly={readOnly} plaintext={readOnly} {...field} />}
      {textarea && <Form.Control as="textarea" readOnly={readOnly} plaintext={readOnly} {...field} />}
      <Form.Control.Feedback className="d-block">{error}</Form.Control.Feedback>
    </Form.Group>
  )
}
