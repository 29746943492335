import { useDetail, useList } from "@inmagik/react-crud"
import { API_URL } from "../constants"
import { DettaglioReportScheda, ReportScheda } from "../types"

export function useReportSchedeList(params: Record<string, string | string[] | number[] | number> = {}) {
  return useList<ReportScheda>(`${API_URL}/report-scheda`, { query: params })
}
export function useDettaglioReportSchedeList(params: Record<string, string | string[] | number[] | number> = {}) {
  return useList<DettaglioReportScheda>(`${API_URL}/dettaglio-report-scheda`, { query: params })
}
export function useReportScheda(id: string) {
  return useDetail<ReportScheda>(`${API_URL}/report-scheda`, id)
}
