import { Link } from "react-router-dom"
import Paginator from "../../components/Paginator"
import Search from "../../components/Search"
import StandardLayout from "../../components/StandardLayout"
import { removeEmpties } from "../../helper"
import { useElementiStradaliList } from "../../hooks/elementistradali"
import { useQsFilters } from "../../hooks/useFilters"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  search: params.get("search") ?? "",
})

const ElementiStradali = () => {
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)

  const elementiStradali = useElementiStradaliList(
    removeEmpties({
      search: filters.search,
      page: String(filters.page),
    })
  )

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="d-flex justify-content-between">
          <div className="page-title">
            <Link to="/dati-base">Dati di base</Link>
            {` / Elementi Stradali`}
          </div>
          <Search
            value={uiFilters.search}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setFiltersDebounced({ search: e.currentTarget.value, page: 1 })
            }
          />
        </div>
      </div>

      <div className="p-3">
        {elementiStradali.data ? (
          <div>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>{"Loc ref."}</th>
                  <th>{"Codice via"}</th>
                  <th>{"Nodo da"}</th>
                  <th>{"Nodo a"}</th>
                  <th>{"Lunghezza"}</th>
                </tr>
              </thead>
              <tbody>
                {elementiStradali.data.results.map((elementoStradale) => (
                  <tr key={elementoStradale.loc_ref}>
                    <td>{elementoStradale.loc_ref}</td>
                    <td>{elementoStradale.streetcode}</td>
                    <td>{elementoStradale.nodo_da}</td>
                    <td>{elementoStradale.nodo_a}</td>
                    <td>{elementoStradale.lunghezza}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      <div className="sticky-footer bg-light border-top">
        <Paginator
          count={elementiStradali?.data?.count ?? 0}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </StandardLayout>
  )
}

export default ElementiStradali
