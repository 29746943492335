import React from "react"
import ReactDOM from "react-dom/client"
import "./styles/index.scss"
import App from "./App.tsx"
import * as Sentry from "@sentry/react"

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://72e0d4fbddb8df1694cc58eaffe17250@o1047151.ingest.us.sentry.io/4508240846127104",
    integrations: [],
  })
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
