import find from "lodash/find"
import { useMemo } from "react"
import { Typeahead } from "react-bootstrap-typeahead"

import "react-bootstrap-typeahead/css/Typeahead.css"
import { Servizio } from "../../types"
import { useServizioList } from "./servizi"
// import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

type ServiziSelectorProps = {
  onChange: (values: Servizio[]) => void
  selectorClassName?: string
  currentFilter: string | string[]
  containerClassName?: string
  labelClassName?: string
  labelText?: string
  multiple?: boolean
  clearButton?: boolean
}

const ServiziSelector = ({
  onChange,
  currentFilter,
  containerClassName = "d-flex align-items-center",
  selectorClassName = "col-sm-9 px-2",
  labelClassName = "col-sm-3 col-form-label-sm",
  labelText = "Servizi",
  multiple = false,
  clearButton = false,
}: ServiziSelectorProps) => {
  const servizi = useServizioList()

  const selectedArray = useMemo(() => {
    if (currentFilter === undefined || !servizi) {
      return []
    }
    const s = Array.isArray(currentFilter) ? currentFilter : [currentFilter]
    return s
      .map((servizio) => find(servizi.data?.results, (item) => item.id === +servizio))
      .filter((item) => item !== undefined) as Servizio[]
  }, [currentFilter, servizi])

  return (
    <div className={containerClassName}>
      <label className={labelClassName}>{labelText}</label>
      <Typeahead
        positionFixed
        paginate
        placeholder="Ricerca servizi"
        id={"ServiziSelector"}
        multiple={multiple}
        clearButton={clearButton}
        isLoading={servizi.isLoading}
        labelKey={(option) => `${(option as Servizio).descrizione}`}
        // onInputChange={(text) => {
        //   loadServizi({ search: text })
        // }}
        options={servizi.data?.results || []}
        onChange={(values) => onChange(values as Servizio[])}
        className={selectorClassName}
        size={"sm"}
        selected={selectedArray}
      />
    </div>
  )
}

export default ServiziSelector
