import { useAction, useDetail, useList } from "@inmagik/react-crud"
import { API_URL } from "../constants"
import { Scheda } from "../types"

export function useCatalogoServiziList(params: Record<string, string | string[] | number[] | number> = {}) {
  return useList<Scheda>(`${API_URL}/schede-servizi`, { query: params })
}

export function useCatalogoServizio(id: string) {
  return useDetail<Scheda>(`${API_URL}/schede-servizi`, id)
}

export function useUploadVNE(id: number) {
  return useAction(`${API_URL}/servizi/${id}/caricamento_vne`, { encode: "formdata" })
}
export function useUploadPDS(id: number) {
  return useAction(`${API_URL}/servizi/${id}/caricamento_pds`, { encode: "formdata" })
}
export function useUploadRipianificato(id: number) {
  return useAction(`${API_URL}/servizi/${id}/caricamento_ripianificato`, { encode: "formdata" })
}

export function useConfermaNonEseguito(id: number) {
  return useAction<ConfermaNonEseguito, void>(`${API_URL}/servizi/${id}/conferma-non-eseguito`, {})
}

export function useUpdatePDS(id: number) {
  return useAction(`${API_URL}/servizi/${id}/modifica_pds`, { responseType: "text" })
}

export function useModificaRipianificato(id: number) {
  return useAction(`${API_URL}/servizi/${id}/modifica_ripianificato`)
}

type ConfermaNonEseguito = {
  id: number
  inizio_val: string
  fine_val: string
  quantita: string
  numero_vie: number
  numero_oggetti: number
  numero_dettagli_pds: number
  area?: number
  timestamp: Date
  servizio: number
}
