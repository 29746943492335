import { useState, useCallback, useMemo } from "react"
import { OverlayTrigger, Popover, PopoverBody, PopoverHeader } from "react-bootstrap"
import { Placement } from "react-bootstrap/esm/types"

type PopoverConfirmProps = {
  okCallBack?: () => void
  buttonClassName?: string
  okButtonClassName?: string
  okButtonText?: string
  cancelButtonClassName?: string
  cancelButtonText?: string
  buttonText?: string
  title?: string
  body?: string
  disabled?: boolean
  placement?: Placement
}

export default function PopoverConfirm({
  okCallBack = () => null,
  buttonClassName = `btn btn-primary`,
  okButtonClassName = `btn btn-sm btn-success`,
  okButtonText = "OK",
  cancelButtonClassName = `btn btn-sm btn-default`,
  cancelButtonText = "Cancel",
  buttonText = "Confirm",
  title = "",
  body = "",
  disabled = false,
  placement = "bottom",
}: PopoverConfirmProps) {
  const [isOpen, setOpen] = useState(false)
  const toggleOpen = useCallback(() => setOpen(!isOpen), [setOpen, isOpen])

  const buttonId = useMemo(() => {
    return "popover-button-" + (Math.random() * 1000).toFixed(0)
  }, [])

  const popoverClick = (
    <Popover title={title} placement={placement}>
      <PopoverHeader>{title}</PopoverHeader>
      <PopoverBody>{body}</PopoverBody>
      <PopoverBody className="d-flex flex-row justify-content-between">
        <button className={okButtonClassName}>{cancelButtonText}</button>
        <button
          className={cancelButtonClassName}
          onClick={() => {
            okCallBack()
          }}
        >
          {okButtonText}
        </button>
      </PopoverBody>
    </Popover>
  )

  return (
    <>
      <OverlayTrigger trigger="focus" placement="bottom" overlay={popoverClick} onToggle={toggleOpen}>
        <button id={buttonId} disabled={disabled} className={buttonClassName} type="button">
          {buttonText}
        </button>
      </OverlayTrigger>
    </>
  )
}
