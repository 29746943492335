import { fetchApi } from "@inmagik/react-crud"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useCallback, useState } from "react"
import { Button } from "react-bootstrap"
import * as yup from "yup"
import { useAuth } from "../../auth"
import { VField } from "../../components/Fields/Fields"
import { transformErrorForForm } from "../../components/Fields/utils"
import ModalForm from "../../components/ModalForm"
import PropertyList from "../../components/PropertyList"
import StandardLayout from "../../components/StandardLayout"
import { API_URL } from "../../constants"
import { ensureDate, mkStringfromDate } from "../../helper"

const schema = yup.object().shape({
  old_password: yup.string().required("password corrente obbligatoria").label("Password corrente"),
  password: yup
    .string()
    .required("nuova password obbligatoria")
    .label("Nuova password")
    .min(8, "La password deve essere lunga almeno 8 caratteri."),
  confirm_password: yup
    .string()
    .required("conferma password obbligatoria")
    .label("Conferma password")
    .oneOf([yup.ref("password"), ""], "Le password non corrispondono."),
})

const initialValues = {
  old_password: "",
  password: "",
  confirm_password: "",
}

const Profile = () => {
  const { user, tokens } = useAuth()
  const [modalStateForm, modalActionsForm] = useModalTrigger()
  const [confirm, setConfirm] = useState<{ type: "danger" | "success"; message: string }>()

  const changeDone = useCallback(() => {
    setConfirm({ type: "success", message: "Password modificata correttamente" })
    setTimeout(() => {
      setConfirm(undefined)
      modalActionsForm.toggle()
    }, 750)
  }, [])

  return (
    user && (
      <StandardLayout>
        <div className="p-4">
          <div className="page-title pb-3">Profilo utente</div>
          <PropertyList
            propLists={[
              [
                ["Nome", user.full_name],
                ["Ente", user.operatore.gestore ? user.operatore.gestore.codice : "Comune di Milano"],
                ["Ultimo login", mkStringfromDate(ensureDate(user.last_login), "DD/MM/YYYY HH:mm")],
                [
                  "Password",
                  <Button className="btn-sm" onClick={() => modalActionsForm.open("dummy")}>
                    Cambia Password
                  </Button>,
                ],
              ],
              [],
            ]}
          />
          {modalStateForm.value && (
            <ModalForm
              title={"Cambia Password"}
              isOpen={modalStateForm.isOpen}
              toggle={modalActionsForm.toggle}
              onClosed={modalActionsForm.onClosed}
              initialValues={initialValues}
              save={(values, actions) => {
                return fetchApi(API_URL + "/utenti/change-password/", {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokens?.access}`,
                  },
                  body: JSON.stringify({
                    old_password: values.old_password,
                    password: values.password,
                  }),
                })
                  .then(() => {
                    changeDone()
                  })
                  .catch((err) => {
                    err.status = err.response.status
                    setConfirm(undefined)
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    actions.setErrors(transformErrorForForm(err) as any)
                  })
              }}
              schema={schema}
              labelSave="conferma"
            >
              <div className="d-flex flex-column">
                <VField name="old_password" label="Password corrente" type="password" required />
                <VField name="password" label="Nuova password" type="password" required />
                <VField name="confirm_password" label="Conferma password" type="password" required />
                {confirm && <div className={`alert alert-${confirm.type} mt-2 py-1`}>{confirm.message}</div>}
              </div>
            </ModalForm>
          )}
        </div>
      </StandardLayout>
    )
  )
}

export default Profile
