import { BrowserRouter } from "react-router-dom"
import { Navigation } from "./Navigations"
import { AuthProvider, useAuth, login, refresh, getUserData, getTokenExpireDate } from "./auth"
import DataProvider from "./DataProvider"
import { DataFetchingAuthContext } from "@inmagik/react-crud"
import Layout from "@inmagik/react-drawers"
import { LoggedInProvider } from "./contexts/LoggedInContext"

function ProvideAuthToDataFetching({ children }: { children: React.ReactNode }) {
  const { tokens } = useAuth()

  const authHeaders: Record<string, string> = tokens?.access ? { Authorization: `Bearer ${tokens?.access}` } : {}

  return <DataFetchingAuthContext.Provider value={authHeaders}>{children}</DataFetchingAuthContext.Provider>
}

function App() {
  return (
    <BrowserRouter>
      <AuthProvider login={login} refresh={refresh} getUserData={getUserData} getTokenExpireDate={getTokenExpireDate}>
        <ProvideAuthToDataFetching>
          <DataProvider>
            <LoggedInProvider >
              <Layout.Master
                top={85}
                left={260}
                right={300}
                bottom={54}
                mode="TLBR"
                rightOpenOnStart={false}
                leftCollapsedWidth={60}
              >
                <Navigation></Navigation>
              </Layout.Master>
            </LoggedInProvider>
          </DataProvider>
        </ProvideAuthToDataFetching>
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App
