import { Field, Formik, getIn } from "formik"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import * as yup from "yup"
import { useAuth } from "../auth"
import Footer from "../components/Footer"
import Header from "../components/Header"

const schema = yup.object().shape({
  email: yup.string().email().required().label("Email"),
  password: yup.string().required().label("Password"),
})

const initialValues = {
  email: "",
  password: "",
}

export function Login() {
  const { performLogin, isLoggingIn, loginError } = useAuth()

  return (
    <div>
      <Header />
      <div className="mt-4">
        <div className="row no-gutters">
          <div className="col-md-4 offset-md-4">
            <div className="text-center">
              <div className="page-title">Login</div>
              <p>Inserisci username e password per accedere</p>
            </div>
            <Formik
              validationSchema={schema}
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values) => {
                performLogin(values)
              }}
            >
              {({ handleSubmit, touched, errors }) => (
                <form onSubmit={handleSubmit} className="p-2 mt-2">
                  <div className="form-group">
                    <Field
                      className={
                        "form-control" + (getIn(errors, "email") && getIn(touched, "email") ? " is-invalid" : "")
                      }
                      placeholder="email"
                      name="email"
                      type="text"
                    />
                    {getIn(errors, "email") && getIn(touched, "email") && (
                      <div className="text-danger">{getIn(errors, "email")}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <Field
                      name="password"
                      className={
                        "form-control" + (getIn(errors, "password") && getIn(touched, "password") ? " is-invalid" : "")
                      }
                      placeholder="password"
                      type="password"
                    />
                    {getIn(errors, "password") && getIn(touched, "password") && (
                      <div className="text-danger">{getIn(errors, "password")}</div>
                    )}
                  </div>
                  <Button className="w-100 mt-2" type="submit" color="primary" disabled={isLoggingIn}>
                    {!isLoggingIn ? "Login" : "Logged in..."}
                  </Button>
                  {loginError && <div className="alert alert-danger mt-2">Email o password errati. Riprova.</div>}
                  <div className="mt-2 text-end">
                    <Link to="/password-dimenticata" className="text-danger no-link p-md">
                      Password dimenticata?
                    </Link>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
