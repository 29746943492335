import { MdSearch } from "react-icons/md"

type SearchProps = {
  value: string
  placeholder?: string
  iconSize?: string | number
  iconClassName?: string
  inputClassName?: string
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
}

export default function Search({
  value,
  placeholder,
  iconSize = 18,
  iconClassName,
  inputClassName = "form-control form-control-sm",
  onChange,
}: SearchProps) {
  return (
    <div className="form-group">
      <div className="input-group p-2 d-flex align-items-center">
        <div className="input-group-prepend d-flex flex-column justify-content-center pe-2">
          <MdSearch className={iconClassName} size={iconSize}></MdSearch>
        </div>
        <input name="search" value={value} placeholder={placeholder} onChange={onChange} className={inputClassName} />
      </div>
    </div>
  )
}
