import React, { createContext, ReactNode, useContext, useEffect } from "react"
import { useDomain } from "../hooks/domain"
import { DomainData } from "../types"
import { useAuth } from "../auth"

interface LoggedInContextProps {
  domainData: DomainData | undefined
}

const LoggedInContext = createContext<LoggedInContextProps | undefined>(undefined)

export const LoggedInProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { user } = useAuth()
  const { data: domainData, refetch } = useDomain()

  useEffect(() => {
    if (user) {
      refetch()
    }
  }, [user, refetch])

  return <LoggedInContext.Provider value={{ domainData }}>{children}</LoggedInContext.Provider>
}

export const useLoggedIn = (): LoggedInContextProps => {
  const context = useContext(LoggedInContext)
  if (!context) {
    throw new Error("useLoggedIn must be used within a LoggedInProvider")
  }
  return context
}
