import dayjs from "dayjs"
import { FormikHelpers } from "formik"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useCallback, useState } from "react"
import { Button, Modal, Spinner } from "react-bootstrap"
import { MdAdd, MdDelete, MdMoving, MdSend } from "react-icons/md"
import * as yup from "yup"
import ModalConfirm from "../../components/ModalConfirm"
import ModalForm from "../../components/ModalForm"
import Paginator from "../../components/Paginator"
import Search from "../../components/Search"
import StandardLayout from "../../components/StandardLayout"
import { useQsFilters } from "../../hooks/useFilters"
import { useGestoriList } from "../../hooks/useGestore"
import {
  useCreateUtente,
  useDeleteMultipleUtente,
  usePartialUpdateUtente,
  useResendMailMultipleUtente,
  useResendMailUtente,
  useUpdateUtente,
  useUtentiList,
} from "../../hooks/utente"
import { User } from "../../types"
import FormUtente from "./FormUtente"
import { FormUser, UserCreate, UserUpdate } from "./types"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  search: params.get("search") ?? "",
})

const initialValues = {
  full_name: "",
  email: "",
  gestore: "",
}

const schema = yup.object().shape({
  full_name: yup.string().required().label("Nome e Cognome"),
  email: yup.string().email().required().label("Email"),
  gestore: yup.string().required().label("Gestore"),
})

export default function Utenti() {
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)
  const {
    data: utenti,
    isLoading,
    refetch,
  } = useUtentiList({
    search: filters.search,
    page: String(filters.page),
  })
  const { data: gestori } = useGestoriList()

  const [modalStateForm, modalActionsForm] = useModalTrigger<FormUser>()
  const [modalStateResendEmail, modalActionsResendEmail] = useModalTrigger<User>()
  const [modalStateMultipleResendEmail, modalActionsMultipleResendEmail] = useModalTrigger()
  const [modalConfirmState, modalConfirmActions] = useModalTrigger()
  const [isModalSubmitting, setIsModalSubmitting] = useState(false)

  const [users, setUsers] = useState<User[]>([])

  const { mutateAsync: createUser } = useCreateUtente()
  const { mutateAsync: updateUser } = useUpdateUtente()
  const { mutateAsync: resendEmailUser } = useResendMailUtente(modalStateResendEmail.value?.id)
  const { mutateAsync: resendEmailMultipleUser } = useResendMailMultipleUtente()
  const { mutateAsync: deleteMultipleUser } = useDeleteMultipleUtente()

  const saveCustomer = useCallback(
    (utente: FormUser, actions: FormikHelpers<User>) => {
      if (utente.id) {
        const payload: UserUpdate = {
          ...utente,
          id: utente.id,
          gestore: utente.gestore !== "comune" && utente.gestore !== undefined ? parseInt(utente.gestore) : null,
        }
        return updateUser(payload)
          .then(() => {
            modalActionsForm.close()
            actions.resetForm()
          })
          .catch((e) => {
            actions.setErrors(e)
          })
      } else {
        const payload: UserCreate = {
          ...utente,
          gestore: utente.gestore !== "comune" && utente.gestore !== undefined ? parseInt(utente.gestore) : null,
        }
        return createUser(payload)
          .then(() => {
            modalActionsForm.close()
            actions.resetForm()
          })
          .catch((e) => {
            actions.setErrors(e.data)
          })
      }
    },
    [modalActionsForm, modalStateForm, updateUser, createUser]
  )

  const [modalStateDelete, modalActionsDelete] = useModalTrigger<User>()
  const { mutateAsync: updatePartialUtente } = usePartialUpdateUtente()

  return (
    <StandardLayout>
      <div className="d-flex align-items-center justify-content-between sticky-header p-3 bg-light shadow-sm">
        <div className="page-title">Utenti</div>
        <div className="d-flex gap-3">
          <Search
            placeholder="Cerca utente..."
            value={uiFilters.search}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setFiltersDebounced({ search: e.currentTarget.value, page: 1 })
            }
          />
          <Button
            onClick={() => {
              modalActionsForm.open(initialValues)
            }}
            className="btn-sm text-white d-flex align-items-center gap-2"
            type="button"
          >
            <span>Crea utente</span>
            <MdAdd />
          </Button>
        </div>
      </div>
      <div className="p-3">
        <div className="me-2">
          Stai visualizzando {utenti?.count ?? 0} di {utenti?.full_count ?? 0} utenti
        </div>
        {users.length > 0 && (
          <div className="bg-light mb-2 py-2 px-3 d-flex border border-secondaery justify-content-between align-items-center rounded">
            <div className="d-flex align-items-center">
              <div className="text-dark">{users.length} utenti selezionati</div>
              <div>
                <Button
                  variant="outline-primary"
                  className="btn-sm d-flex align-items-center ms-2 gap-2"
                  onClick={() => {
                    modalActionsMultipleResendEmail.open("dummy")
                  }}
                >
                  Invita utenti
                  <MdMoving />
                </Button>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <Button
                variant="outline-danger"
                className="btn-sm d-flex align-items-center gap-2"
                onClick={() => {
                  modalConfirmActions.open("dummy")
                }}
              >
                <MdDelete />
                Elimina utenti
              </Button>
            </div>
          </div>
        )}
        {isLoading && <Spinner role="status" variant="secondary" />}
        {!isLoading && (
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th>Email</th>
                <th>Nome e Cognome</th>
                <th>Ruolo</th>
                <th>Ultimo accesso</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {utenti?.results.map((utente) => (
                <tr key={utente.id}>
                  <td className="d-flex align-items-center">
                    <div>
                      <input
                        type="checkbox"
                        checked={users.includes(utente)}
                        onChange={() => {
                          if (users.includes(utente)) {
                            setUsers(users.filter((user) => user !== utente))
                          } else {
                            setUsers([...users, utente])
                          }
                        }}
                      />
                    </div>
                    <div
                      className="p-md text-body-secondary ms-2"
                      onClick={() => {
                        modalActionsForm?.open({
                          ...utente,
                          gestore: utente.operatore?.gestore?.id.toString() ?? "comune",
                        })
                      }}
                    >
                      <span className="text-primary pointer">{utente.email}</span>
                    </div>
                  </td>
                  <td className="p-md text-body-secondary">{utente.full_name}</td>
                  <td className="text-capitalize p-md text-body-secondary">
                    {utente.operatore?.gestore?.descrizione ?? "comune"}
                  </td>
                  <td className="p-md text-body-secondary">
                    {utente.last_login ? (
                      dayjs(utente.last_login).format("DD/MM/YYYY HH:mm")
                    ) : (
                      <Button
                        onClick={() => {
                          modalActionsResendEmail.open(utente)
                        }}
                        variant="outline-primary"
                        className="btn-sm d-flex align-items-center gap-2"
                        type="button"
                      >
                        Reinvita utente
                        <MdSend />
                      </Button>
                    )}
                  </td>
                  <td>
                    <MdDelete
                      className="text-primary pointer"
                      onClick={() => {
                        modalActionsDelete?.open(utente)
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="sticky-footer bg-light border-top">
        <Paginator count={utenti?.count ?? 0} currentPage={uiFilters.page} goToPage={(page) => setFilters({ page })} />
      </div>

      <Modal
        show={modalStateResendEmail.isOpen}
        onHide={modalActionsResendEmail.toggle}
        contentClassName="bg-topbar"
        centered
      >
        <Modal.Header>
          <Modal.Title>Reinvia email di attivazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <p>Stai per reinviare l'email di attivazione a {modalStateResendEmail.value?.email}</p>
            <p>Sei sicuro di voler procedere?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              resendEmailUser({}).then(() => {
                modalActionsResendEmail.close()
              })
            }}
            variant="outline-primary"
            className="btn-sm d-flex align-items-center gap-2"
          >
            Reinvia email
            <MdSend />
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalStateMultipleResendEmail.isOpen}
        onHide={modalActionsMultipleResendEmail.toggle}
        contentClassName="bg-topbar"
        centered
      >
        <Modal.Header>
          <Modal.Title>Reinvia email di attivazione</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <p>Stai per reinviare l'email di attivazione a {users.length} utenti</p>
            <p>Sei sicuro di voler procedere?</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={async () => {
              resendEmailMultipleUser({ ids: users.map((u) => u.id) }).then(() => {
                modalActionsMultipleResendEmail.close()
                setUsers([])
              })
            }}
            variant="outline-primary"
            className="btn-sm d-flex align-items-center gap-2"
          >
            Reinvia email
            <MdSend />
          </Button>
        </Modal.Footer>
      </Modal>

      {modalStateForm.value && (
        <ModalForm<FormUser>
          title={modalStateForm.value ? "Modifica utente" : "Crea utente"}
          isOpen={modalStateForm.isOpen}
          toggle={modalActionsForm.toggle}
          onClosed={modalActionsForm.onClosed}
          initialValues={modalStateForm.value ?? initialValues}
          save={saveCustomer}
          schema={schema}
          size="lg"
        >
          {gestori && <FormUtente gestori={gestori.results} />}
        </ModalForm>
      )}
      {modalConfirmState.value && (
        <ModalConfirm
          okButtonText="Elimina"
          cancelButtonText="Annulla"
          isOpen={modalConfirmState.isOpen}
          toggle={modalConfirmActions.toggle}
          onClosed={modalConfirmActions.onClosed}
          title="Elimina utenti"
          okCallBack={() => {
            return deleteMultipleUser({ ids: users.map((u) => u.id) })
              .then(() => {
                modalConfirmActions.close()
                setUsers([])
                refetch()
              })
              .finally(() => setIsModalSubmitting(false))
          }}
          body={"Sei sicuro di voler eliminare " + users.length + " utenti?"}
          isSubmitting={isModalSubmitting}
        />
      )}

      {modalStateDelete.value && (
        <ModalConfirm
          isOpen={modalStateDelete.isOpen}
          toggle={modalActionsDelete.toggle}
          onClosed={modalActionsDelete.onClosed}
          title="Elimina utente"
          okButtonText="Elimina"
          body={"Sei sicuro di voler eliminare l'utente " + modalStateDelete.value?.full_name + "?"}
          okCallBack={async () => {
            setIsModalSubmitting(true)
            await updatePartialUtente({
              id: modalStateDelete.value!.id,
              is_active: false,
            })
              .then(() => {
                modalActionsDelete.close()
                refetch()
              })
              .finally(() => setIsModalSubmitting(false))
          }}
          isSubmitting={isModalSubmitting}
        />
      )}
    </StandardLayout>
  )
}
