import { Link } from "react-router-dom"
import Paginator from "../../components/Paginator"
import Search from "../../components/Search"
import StandardLayout from "../../components/StandardLayout"
import { removeEmpties } from "../../helper"
import { useCatalogoServiziList } from "../../hooks/catalogoservizi"
import { useQsFilters } from "../../hooks/useFilters"
import { Scheda } from "../../types"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  search: params.get("search") ?? "",
})

function getClassificazioneLink(schedaServizio: Scheda) {
  const classificazione = schedaServizio.classificazione
  if (!classificazione) {
    return ""
  }
  return (
    <Link to={`/classificazioni/${classificazione}?scheda=${schedaServizio.id}`}>
      {schedaServizio.classificazione_nome}
    </Link>
  )
}

const CatalogoServizi = () => {
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)

  const schedeServizi = useCatalogoServiziList(
    removeEmpties({
      search: filters.search,
      page: String(filters.page),
    })
  )

  return (
    <StandardLayout>
      <div className="d-flex justify-content-between sticky-header p-3 bg-light shadow-sm">
        <div className="page-title">Catalogo Servizi / Schede</div>
        <Search
          value={uiFilters.search}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setFiltersDebounced({ search: e.currentTarget.value, page: 1 })
          }
        />
      </div>

      <div className="p-3">
        {schedeServizi ? (
          <div>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Descrizione</th>
                  <th>Servizi</th>
                  <th>Codice scheda</th>
                  <th>Codice macroscheda Comune</th>
                  <th>Periodicità Report</th>
                  <th>Tipo oggetto</th>
                  <th>Unità di Misuta</th>
                  <th>Classificazione</th>
                </tr>
              </thead>
              <tbody>
                {schedeServizi.data?.results.map((scheda) => (
                  <tr key={scheda.id}>
                    <td>{scheda.id}</td>
                    <td>
                      <Link to={`/catalogo-servizi/schede/${scheda.id}`}>{scheda.descrizione}</Link>
                    </td>
                    <td>
                      {scheda.servizi && (
                        <span className={`badge ${scheda.servizi.length ? "bg-primary" : "bg-secondary"}`}>
                          {scheda.servizi.length}
                        </span>
                      )}
                    </td>
                    <td>{scheda.codice_scheda}</td>
                    <td>{scheda.codice_macroscheda_comune}</td>
                    <td>{scheda.periodicita_report}</td>
                    <td>{scheda.tipo_oggetto}</td>
                    <td>{scheda.um}</td>
                    <td>{getClassificazioneLink(scheda)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      <div className="sticky-footer bg-light border-top">
        <Paginator
          count={schedeServizi?.data?.count ?? 0}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </StandardLayout>
  )
}

export default CatalogoServizi
