import { useState } from "react"
import { Button } from "react-bootstrap"
import { fetchApi } from "@inmagik/react-crud"
import { Field, Formik, getIn } from "formik"
import * as yup from "yup"
import { Link, useParams } from "react-router-dom"
import { API_URL } from "../constants"
import Header from "../components/Header"
import Footer from "../components/Footer"

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password è un campo obbligatorio")
    .min(8, "La password deve essere lunga almeno 8 caratteri."),
  repeatPassword: yup
    .string()
    .required("Conferma Password è un campo obbligatorio")
    .oneOf([yup.ref("password"), ""], "Le password non corrispondono."),
})

const initialValues = {
  password: "",
  repeatPassword: "",
}

export function AttivazioneAccount() {
  const [confirm, setConfirm] = useState(false)
  const { token } = useParams()
  return (
    <div>
      <Header />
      <div className="mt-4">
        <div className="row no-gutters">
          <div className="col-md-4 offset-md-4">
            <div className="text-center">
              <div className="page-title">ATTIVAZIONE ACCOUNT</div>
              <Formik
                validationSchema={schema}
                initialValues={initialValues}
                enableReinitialize
                onSubmit={async (values) => {
                  await fetchApi(API_URL + "/utenti/users/activate_account/", {
                    method: "POST",
                    body: JSON.stringify({
                      ...values,
                      token,
                    }),

                    headers: {
                      "Content-Type": "application/json",
                    },
                  }).then(() => {
                    setConfirm(true)
                  })
                }}
              >
                {({ handleSubmit, touched, errors }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mt-2">
                      <div className="form-group">
                        <label htmlFor="password" className="label-m text-body-secondary">
                          Password
                        </label>
                        <Field
                          name="password"
                          type="password"
                          className={
                            "form-control" +
                            (getIn(errors, "password") && getIn(touched, "password") ? " is-invalid" : "")
                          }
                        />
                        {getIn(errors, "password") && getIn(touched, "password") && (
                          <div className="text-danger">{getIn(errors, "password")}</div>
                        )}
                      </div>
                      <div className="form-group mt-2">
                        <label htmlFor="repeatPassword" className="label-m text-body-secondary">
                          Conferma Password
                        </label>
                        <Field
                          name="repeatPassword"
                          type="password"
                          className={
                            "form-control" +
                            (getIn(errors, "repeatPassword") && getIn(touched, "repeatPassword") ? " is-invalid" : "")
                          }
                        />
                        {getIn(errors, "repeatPassword") && getIn(touched, "repeatPassword") && (
                          <div className="text-danger">{getIn(errors, "repeatPassword")}</div>
                        )}
                      </div>
                    </div>
                    <div>
                      {confirm && (
                        <div className="alert alert-success mt-2 py-1">
                          Account attivato con successo <br />
                          Procedi con il <Link to="/login">login</Link>
                        </div>
                      )}
                    </div>

                    <Button type="submit" variant="secondary" className="w-100 mt-2 text-white">
                      Attiva account
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
