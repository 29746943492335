import { FieldProps, getIn } from "formik"
import React, { useCallback } from "react"
import { Col, Form } from "react-bootstrap"

interface FileFieldProps extends FieldProps {
  rowLayout?: boolean
  label: string
}

export const FileField = ({ field, form, label, ...props }: FileFieldProps) => {
  const { errors } = form
  const error = getIn(errors, field.name)

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      form.setFieldValue(field.name, e.target?.files?.[0])
    },
    [field.name, form]
  )

  return (
    <Col sm={8}>
      <Form.Group controlId="formFile" className="mb-3">
        {label && <Form.Label>{label}</Form.Label>}
        <React.Fragment>
          <Form.Control required type="file" onChange={handleChange} {...props} />
          <Form.Control.Feedback type="invalid" className="d-block">
            {error}
          </Form.Control.Feedback>
        </React.Fragment>
      </Form.Group>
    </Col>
  )
}
