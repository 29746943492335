import { useData, useDetail, useList, usePlainList } from "@inmagik/react-crud"
import { API_URL } from "../../constants"
import { DettaglioPDS, NonEseguito, OverviewTabella, OverviewVia, Via } from "../../types"

export function useVieList(params: Record<string, string | string[] | number[] | number> = {}) {
  return useList<Via>(`${API_URL}/vie`, { query: params })
}
export function useVia(id: string) {
  return useDetail<Via>(`${API_URL}/vie`, id)
}

export function useVieTypeahead(params: Record<string, string | string[] | number[] | number> = {}) {
  return usePlainList<Via>(`${API_URL}/vie/typeahead`, { query: params })
}

export function useViaOverview(loc_ref: string, data: string) {
  return usePlainList<OverviewVia>(`${API_URL}/vie/${loc_ref}/overview/${data}`)
}

export function useOverviewTabella(
  type: "pianificato" | "non-eseguito",
  schedaId: string,
  date: string,
  params: Record<string, string | string[] | number | number[]> = {}
) {
  return useList<OverviewTabella>(`${API_URL}/vie/${type}/${schedaId}/${date}`, { query: params })
}
export function useOverviewMappa(
  type: "pianificato" | "non-eseguito",
  schedaId: string,
  date: string,
  params: Record<string, string | string[] | number[]> = {}
) {
  return useList<OverviewTabella>(`${API_URL}/vie/geo-${type}/${schedaId}/${date}`, { query: params })
}

export function useOverviewDetail(
  type: "pianificato" | "non-eseguito",
  schedaId: string,
  date: string,
  storid: string
) {
  return useData<DettaglioPDS[] | NonEseguito[]>(`${API_URL}/vie/${storid}/${type}/${schedaId}/${date}`, {
    reactQuery: { enabled: !!storid },
  })
}
