import { Link } from "react-router-dom"
import Paginator from "../../components/Paginator"
import Search from "../../components/Search"
import StandardLayout from "../../components/StandardLayout"
import { useVieList } from "../../components/ViaSelector/vie"
import { removeEmpties } from "../../helper"
import { useQsFilters } from "../../hooks/useFilters"

const initFilters = (params: URLSearchParams) => ({
  page: Number(params.get("page") ?? 1),
  search: params.get("search") ?? "",
})

const Vie = () => {
  const { filters, uiFilters, setFilters, setFiltersDebounced } = useQsFilters(initFilters)

  const vie = useVieList(
    removeEmpties({
      search: filters.search,
      page: String(filters.page),
    })
  )

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="d-flex justify-content-between">
          <div className="page-title">
            <Link to="/dati-base">{"Dati di base"}</Link>
            {` / Vie`}
          </div>
          <Search
            value={uiFilters.search}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setFiltersDebounced({ search: e.currentTarget.value, page: 1 })
            }
          />
        </div>
      </div>

      <div className="p-3">
        {vie.data ? (
          <div className="">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>{"Loc ref."}</th>
                  <th>{"Codice via"}</th>
                  <th>{"Tipo via"}</th>
                  <th>{"Toponimo"}</th>
                </tr>
              </thead>
              <tbody>
                {vie.data.results.map((via) => (
                  <tr key={via.loc_ref}>
                    <td>{via.loc_ref}</td>
                    <td>{via.streetcode}</td>
                    <td>{via.tipovia}</td>
                    <td>
                      <Link to={`/dati-base/vie/list/${via.streetcode}/programmazioni`}>{via.toponym}</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>

      <div className="sticky-footer bg-light border-top">
        <Paginator
          count={vie?.data?.count ?? 0}
          currentPage={uiFilters.page}
          goToPage={(page) => setFilters({ page })}
        />
      </div>
    </StandardLayout>
  )
}

export default Vie
