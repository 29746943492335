import { debounce, find } from "lodash"
import { useMemo, useState } from "react"
import { Typeahead } from "react-bootstrap-typeahead"
import "react-bootstrap-typeahead/css/Typeahead.css"
import { Via } from "../../types"
import { useVieTypeahead } from "./vie"

type ViaSelectorProps = {
  onChange: (values: Via[]) => void
  currentFilter: string | string[] | undefined
  selectorClassName?: string
  containerClassName?: string
  labelClassName?: string
  labelText?: string
  multiple?: boolean
  clearButton?: boolean
}

const ViaSelector = ({
  onChange,
  currentFilter,
  containerClassName = "d-flex align-items-center",
  selectorClassName = "col-sm-10 px-2",
  labelClassName = "col-sm-2 col-form-label-sm",
  labelText = "Vie",
  multiple = false,
  clearButton = false,
}: ViaSelectorProps) => {
  const [debSearch, setDebSearch] = useState("")

  const handleSearchChange = useMemo(() => debounce(setDebSearch, 300), [])

  const filters = useMemo(() => {
    const f: Record<string, string | string[]> = {
      search: debSearch,
    }
    if (currentFilter) {
      f.selected = currentFilter
    }
    return f
  }, [debSearch, currentFilter])

  const vie = useVieTypeahead(filters)
  const selectedArray = useMemo(() => {
    if (currentFilter === undefined || !vie) {
      return []
    }
    const s = Array.isArray(currentFilter) ? currentFilter : [currentFilter]
    return s
      .map((via) => find(vie.data, (item) => item.streetcode === +via))
      .filter((item) => item !== undefined) as Via[]
  }, [currentFilter, vie])

  return (
    <div className={containerClassName}>
      <label className={labelClassName}>{labelText}</label>
      <Typeahead
        positionFixed
        paginate
        multiple={multiple}
        clearButton={clearButton}
        placeholder="Ricerca vie"
        id={"VieSelector"}
        isLoading={vie.isLoading}
        labelKey={(option) => `${(option as Via).toponym}`}
        onInputChange={(text) => {
          handleSearchChange(text)
        }}
        options={vie.data || []}
        onChange={(values) => onChange(values as Via[])}
        className={selectorClassName}
        size={"sm"}
        selected={selectedArray}
        filterBy={() => true}
      />
    </div>
  )
}

export default ViaSelector
