import { API_URL } from "./constants"
import { MakeAuthTools } from "./hooks/auth"
import { User, Tokens, Credentials } from "./types"

export async function refresh(tokens: Tokens) {
  const { refresh } = tokens
  const res = await fetch(`${API_URL}/token/refresh/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refresh: refresh }),
  })
  if (res.ok) {
    const refreshedTokens = await res.json()
    return { ...tokens, ...refreshedTokens }
  }
  return null
}

export async function getUserData(tokens: Tokens) {
  const { access } = tokens
  const userRes = await fetch(`${API_URL}/profile/`, {
    headers: {
      Authorization: `Bearer ${access}`,
    },
  })
  if (userRes.ok) {
    const user = await userRes.json()
    return user as User
  }
  return null
}

export async function login(credentials: Credentials) {
  const res = await fetch(`${API_URL}/token/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
  if (res.ok) {
    const tokens = await res.json()
    return tokens as Tokens
  } else {
    if (res.status === 401 || res.status === 400) {
      const error = await res.json()
      console.error(error)
      throw new Error(error)
    } else {
      const text = res.text()
      console.error(text)
    }
  }
  return null
}

function getExpireDate(token: string) {
  const payload = token.split(".")[1]
  const decoded = atob(payload)
  const data = JSON.parse(decoded)
  const exp = data.exp * 1000
  return new Date(exp)
}

export function getTokenExpireDate(tokens: { access: string }) {
  return getExpireDate(tokens.access)
}

export const [AuthProvider, useAuth] = MakeAuthTools<User, Tokens, Credentials>()
