import { VField } from "../../components/Fields/Fields"
import { Gestore } from "../../types"

export default function FormUtente({ gestori }: { gestori: Gestore[] }) {
  return (
    <div className="row">
      <div className="col-6">
        <VField name="full_name" label="Nome e Cognome" type="text" required />
      </div>
      <div className="col-6">
        <VField name="email" label="Email" type="email" required />
      </div>
      <div className="col-6">
        <VField name="gestore" label="Gestore" as="select" required>
          <option value="">Seleziona un ruolo</option>
          {gestori.map((gestore) => {
            return <option key={gestore.id} value={gestore.id}>{gestore.descrizione}</option>
          })}
          <option value={"comune"}>Comune</option>
        </VField>
      </div>
    </div>
  )
}
