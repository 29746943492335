import dayjs from "dayjs"
import { keyBy } from "lodash"
import { useEffect, useMemo, useState } from "react"
import { Button } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import MyDatePicker from "../../components/MyDataPicker"
import PropertyList from "../../components/PropertyList"
import StandardLayout from "../../components/StandardLayout"
import { useViaOverview, useVieList } from "../../components/ViaSelector/vie"
import { mkStringfromDate } from "../../helper"
import { getServizioLink } from "../NonEseguito/NonEseguito"
import { MyMap } from "./MyMap"
import { useQsFilters } from "../../hooks/useFilters"

const initFilters = (params: URLSearchParams) => ({
  date: params.get("date") ?? dayjs().format("YYYY-MM-DD"),
})

const ViaDettaglio = () => {
  const idVia = useParams().id!
  const { filters, setFilters } = useQsFilters(initFilters)

  const { data: vie } = useVieList({ streetcode: idVia })
  const { data: overview } = useViaOverview(vie?.results[0].loc_ref ?? "", filters.date)
  const servizi = useMemo(() => keyBy(overview, "servizio.id"), [overview])
  const [selected, setSelected] = useState("")

  useEffect(() => {
    setSelected(Object.keys(servizi)[0])
  }, [servizi])

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="d-flex justify-content-between">
          <div className="page-title">
            <Link to="/dati-base">{"Dati di base"}</Link>
            {" / "}
            <Link to="/dati-base/vie/list">{"Vie"}</Link>
            {` / ${vie?.results[0].toponym}`}
          </div>
        </div>
      </div>
      <div className="d-flex p-3 h-50 justify-content-between">
        {vie?.results[0] && (
          <PropertyList
            propLists={[
              [
                ["Loc Ref.", vie?.results[0].loc_ref],
                ["Codice Via", vie?.results[0].streetcode],
                ["Tipo", vie?.results[0].tipovia],
                ["Toponimo", vie?.results[0].toponym],
              ],
            ]}
          ></PropertyList>
        )}
        {vie?.results[0].streetcode && (
          <div className="row no-gutters h-100 w-50">
            <div className="col-sm-12 h-100">
              <MyMap streetcode={vie?.results[0].streetcode.toString()} />
            </div>
          </div>
        )}
      </div>

      <div className="p-3">
        <div className="d-flex justify-content-between align-items-center py-1">
          <div className="d-flex align-items-center">
            {Object.keys(servizi).map((item) => {
              return (
                <Button
                  key={item}
                  className={`nav-item pointer me-1 btn-sm  ${item === selected ? "btn-primary" : "btn-light"}`}
                  onClick={() => setSelected(item)}
                >
                  {servizi[item].servizio.scheda_data.codice_scheda}
                </Button>
              )
            })}
          </div>
          <MyDatePicker
            containerClassName="d-flex align-items-center w-25"
            selected={filters.date}
            onChange={(date) => {
              if (date) setFilters({ date: mkStringfromDate(date, "YYYY-MM-DD") ?? dayjs().format("YYYY-MM-DD") })
              else setFilters({ date: dayjs().format("YYYY-MM-DD") })
            }}
          />
        </div>
        {selected && servizi && servizi[selected]?.servizio && (
          <div className="page-title">
            {servizi[selected].servizio.scheda_data.codice_scheda} {servizi[selected].servizio.scheda_data.titolo}
          </div>
        )}
        {selected && servizi && servizi[selected]?.dettagli.length > 0 && (
          <div className="pt-3">
            <div>Programmazioni</div>
            <table className="table table-striped table-bordered mt-1">
              <thead>
                <tr>
                  <th>Piano Servizi</th>
                  <th>Codice Viario</th>
                  <th>Loc ref</th>
                  <th>Unità territoriale</th>
                  <th>Descrizione elemento</th>
                  {/* <th>{`Loc ref ${pianoServizi ? `(${pianoServizi.servizio.scheda_data.tipo_oggetto})` : ""}`}</th> */}
                  <th>Data</th>
                  <th>Giorno</th>
                  <th>Ora inizio</th>
                  <th>Ora fine</th>
                  <th>Quantità</th>
                  <th>Squadra</th>
                  <th>Lato</th>
                  <th>Servizio gestore</th>
                </tr>
              </thead>
              <tbody>
                {servizi[selected].dettagli.map((dettaglioPDS) => {
                  return (
                    <tr key={dettaglioPDS.piano_servizi}>
                      <td>{dettaglioPDS.piano_servizi}</td>
                      <td>{dettaglioPDS.streetcode}</td>
                      <td>{dettaglioPDS.loc_ref}</td>
                      <td>{dettaglioPDS.unita_territoriale}</td>
                      <td>{dettaglioPDS.descrizione_elemento}</td>
                      <td>{filters.date}</td>
                      {/* <td>{mkStringfromDate(dettaglioPDS.data, "DD-MM-YYYY")}</td> */}
                      <td>{dettaglioPDS.tipo_giorno}</td>
                      <td>{dettaglioPDS.ora_inizio}</td>
                      <td>{dettaglioPDS.ora_fine}</td>
                      <td>{dettaglioPDS.quantita}</td>
                      <td>{dettaglioPDS.squadra}</td>
                      <td>{dettaglioPDS.lato}</td>
                      <td>{dettaglioPDS.servizio_gestore}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
        {selected && servizi && servizi[selected]?.dettagli.length === 0 && (
          <div className="pt-3">
            <div>Programmazioni: non presenti</div>
          </div>
        )}
        {selected && servizi && servizi[selected]?.non_eseguito.length > 0 && (
          <div className="pt-3">
            <div>Non Eseguiti</div>
            <table className="table table-striped table-bordered mt-1">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Servizio</th>
                  <th>Data</th>
                  <th>Nome via</th>
                  <th>Streetcode</th>
                  <th>Loc_ref</th>
                  <th>Unità territoriale</th>
                  <th>Descrizione elemento</th>
                  <th>Causale</th>
                  <th>Esecuzione</th>
                  <th>Minuti ritardo</th>
                  <th>Quantità</th>
                </tr>
              </thead>
              <tbody>
                {servizi[selected].non_eseguito.map((nonEseguito) => (
                  <tr key={nonEseguito.id}>
                    <td>{nonEseguito.id}</td>
                    <td>{getServizioLink(nonEseguito)}</td>
                    <td>{mkStringfromDate(nonEseguito.data, "DD/MM/YYYY")}</td>
                    {/* <td>{nonEseguito.toponym}</td> */}
                    <td>{vie?.results[0].toponym}</td>
                    <td>{nonEseguito.streetcode}</td>
                    <td>{nonEseguito.loc_ref}</td>
                    <td>{nonEseguito.unita_territoriale}</td>
                    <td>{nonEseguito.descrizione_elemento}</td>
                    <td>{nonEseguito.causale}</td>
                    <td>{mkStringfromDate(nonEseguito.dataora_esecuzione, "DD/MM/YYYY HH:mm")}</td>
                    <td>{nonEseguito.minuti_ritardo}</td>
                    <td>{nonEseguito.quantita}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {selected && servizi && servizi[selected]?.non_eseguito.length === 0 && (
          <div className="pt-3">
            <div>Non Eseguiti: non presenti</div>
          </div>
        )}
      </div>
    </StandardLayout>
  )
}

export default ViaDettaglio
