import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../auth"

type GuestLayoutProps = {
  redirect_to: string
}

export function GuestLayout({ redirect_to }: GuestLayoutProps) {
  const auth = useAuth()

  if (auth.user) {
    return <Navigate to={redirect_to} replace />
  }

  return (
    <div className="w-100 h-100">
      <Outlet />
    </div>
  )
}
