import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useCallback, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useAuth } from "../../auth"
import ModalConfirm from "../../components/ModalConfirm"
import StandardLayout from "../../components/StandardLayout"
import { mkStringfromDate } from "../../helper"
import { useDeletePiano, usePianiList } from "../../hooks/piani"
import { Piano, Scheda } from "../../types"
import HeaderServizi from "./HeaderServizi"
import ModificaRipianificatoForm from "./ModificaRipianificatoForm"
import UploadModal from "./UploadModal"

const filtroPianiAttivi = { bozza: 0, ripianificato: 1 }
const filtroPianiBozza = { bozza: 1, ripianificato: 1 }

type Props = {
  schedaServizio: Scheda
}

export default function RipianificatoServizio({ schedaServizio }: Props) {
  const { user } = useAuth()
  const idServizio = parseInt(useParams().idServizio!)
  const servizio = schedaServizio.servizi!.find((ser) => ser.id === idServizio)!

  const pianiAttivi = usePianiList(servizio.id, filtroPianiAttivi)
  const pianiBozza = usePianiList(servizio.id, filtroPianiBozza)
  const { mutateAsync: deletePiano } = useDeletePiano()

  const [deleteModalState, deleteModalActions] = useModalTrigger<Piano>()
  const [uploadModalState, uploadModalActions] = useModalTrigger()
  const [isModalSubmitting, setIsModalSubmitting] = useState(false)

  const refetch = useCallback(() => {
    pianiAttivi.refetch()
    pianiBozza.refetch()
  }, [pianiAttivi, pianiBozza])

  const confirmDeleteRipianificato = useCallback(
    (nonEseId: number) => {
      setIsModalSubmitting(true)
      return deletePiano(nonEseId)
        .then(() => {
          deleteModalActions.close()
          refetch()
        })
        .finally(() => setIsModalSubmitting(false))
    },
    [deleteModalActions, deletePiano, refetch]
  )

  return (
    <StandardLayout>
      {schedaServizio && servizio && (
        <>
          <HeaderServizi schedaServizio={schedaServizio} servizio={servizio} open="extralavorazioni" />
          <div className="px-3 pb-3">
            {!user?.is_operatore_comune && (
              <>
                <div className="">
                  <div className="page-title">Extra lavorazioni in bozza</div>

                  {pianiBozza && pianiBozza.data?.length === 0 && <p>Nessuna extra lavorazione in bozza</p>}

                  {pianiBozza.data && pianiBozza.data?.length > 0 && (
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Tipo piano</th>
                          <th>Inizio val.</th>
                          <th>Fine val.</th>
                          <th>Quantità tot.</th>
                          <th>Numero vie</th>
                          <th>N. vie su grafo</th>
                          <th>N. oggetti su grafo</th>
                          <th>Azioni</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pianiBozza.data.map((piano) => (
                          <tr key={piano.id}>
                            <td>{piano.id}</td>
                            <td>{piano.ripianificato ? "Ripianificato" : "Pianificato"}</td>
                            <td>{mkStringfromDate(piano.inizio_val, "YYYY-MM-DD")}</td>
                            <td>{mkStringfromDate(piano.fine_val, "YYYY-MM-DD")}</td>
                            <td>{piano.totale_lavorazioni_piano?.quantita ?? ""}</td>
                            <td>{piano.totale_lavorazioni_piano?.numero_vie ?? ""}</td>
                            <td>{piano.totale_lavorazioni_piano?.numero_vie_agganciate ?? ""}</td>
                            <td>{piano.totale_lavorazioni_piano?.numero_oggetti ?? ""}</td>
                            <td>
                              <button className="btn btn-warning btn-sm" onClick={() => deleteModalActions.open(piano)}>
                                {"Elimina"}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}

                  <div>
                    <button className="btn btn-sm btn-primary" onClick={() => uploadModalActions.open("dummy")}>
                      Carica bozza extralavorazioni
                    </button>
                  </div>
                </div>

                <hr />
              </>
            )}

            {servizio && !user?.is_operatore_comune && pianiBozza.data && pianiBozza.data?.length > 0 && (
              <>
                <ModificaRipianificatoForm
                  idServizio={servizio.id}
                  pianiBozza={pianiBozza.data}
                  refetch={() => refetch()}
                ></ModificaRipianificatoForm>
                <hr />
              </>
            )}

            {pianiAttivi ? (
              <div className="p-3">
                <div className="page-title">Extra lavorazioni confermate</div>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Tipo piano</th>
                      <th>Inizio val.</th>
                      <th>Fine val.</th>
                      <th>Quantità tot.</th>
                      <th>Numero vie</th>
                        <th>N. vie su grafo</th>
                        <th>N. oggetti su grafo</th>
                      <th>Azioni</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pianiAttivi.data &&
                      pianiAttivi.data?.map((piano) => (
                        <tr key={piano.id}>
                          <td>{piano.id}</td>
                          <td>{piano.ripianificato ? "Ripianificato" : "Pianificato"}</td>
                          <td>{mkStringfromDate(piano.inizio_val, "YYYY-MM-DD")}</td>
                          <td>{mkStringfromDate(piano.fine_val, "YYYY-MM-DD")}</td>
                          <td>{piano.totale_lavorazioni_piano?.quantita ?? ""}</td>
                          <td>{piano.totale_lavorazioni_piano?.numero_vie ?? ""}</td>
                          <td>{piano.totale_lavorazioni_piano?.numero_vie_agganciate ?? ""}</td>
                          <td>{piano.totale_lavorazioni_piano?.numero_oggetti ?? ""}</td>
                          <td>
                            <Link to={`/programmazioni/${piano.id}`}>Dettagli</Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
        </>
      )}

      {servizio && uploadModalState.value && (
        <UploadModal
          isOpen={uploadModalState.isOpen}
          toggle={uploadModalActions.toggle}
          onClosed={uploadModalActions.onClosed}
          idServizio={servizio.id}
          fineValRequired
          title="Caricamento extra lavorazioni"
          typeLoad="caricamento_ripianificato"
          onUpload={refetch}
        ></UploadModal>
      )}

      {deleteModalState.value && deleteModalState.isOpen && (
        <ModalConfirm
          isOpen={deleteModalState.isOpen}
          toggle={deleteModalActions.toggle}
          onClosed={deleteModalActions.onClosed}
          title="Elimina bozza piano"
          body={`Confermi l'eliminazione del piano in bozza?`}
          okCallBack={() => confirmDeleteRipianificato(deleteModalState.value!.id)}
          isSubmitting={isModalSubmitting}
        />
      )}
    </StandardLayout>
  )
}
