import { useAction, useData, useList } from "@inmagik/react-crud"
import { API_URL } from "../constants"
import { Classificazione } from "../types"

export function useClassificazioniList(params: Record<string, string | string[] | number[] | number> = {}) {
  return useList<Classificazione>(`${API_URL}/classificazioni`, { query: params })
}

export function useDettaglioClassificazione(id: string, scheda: string) {
  return useData<Classificazione>(`${API_URL}/classificazioni/${id}/dettaglio/`, { query: { scheda: scheda } })
}

export function useDownloadClassificazioneCSV(id: number) {
  return useAction<Blob, void>(`${API_URL}/classificazioni/${id}/csv`, { method: "GET", responseType: "blob" })
}
