export type PropertyListProps = {
  propLists: Array<Array<[string, React.ReactNode]>>
}

export default function PropertyList({ propLists = [] }: PropertyListProps) {
  const hasTwoColumn = propLists[1]?.length > 0
  return (
    <div className={`row ${hasTwoColumn ? "w-100" : "w-50"}`}>
      {propLists.map((p, i) => (
        <div className={hasTwoColumn ? `col-sm-6` : `col-sm-12`} key={i}>
          {p.map((prop, j) => (
            <div className="d-flex border-bottom border-white" key={j}>
              <div className="w-50 bg-grey p-2 text-end">{prop[0]}</div>
              {typeof prop[1] === "number" ? (
                <div className="w-50 p-2">{prop[1].toString()}</div>
              ) : (
                <div className="w-50 p-2">{prop[1]}</div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
