import { Fragment, useMemo } from "react"
import { MdDownload, MdList, MdMap } from "react-icons/md"
import { Link } from "react-router-dom"
import StandardLayout from "../../components/StandardLayout"
import { useDominio } from "../../hooks/area"
import useGeoJSONDownload from "../../hooks/useGeoJSONDownload"

const DatiBase = () => {
  const downloadGeo = useGeoJSONDownload()
  const { data: tipiDominio } = useDominio()

  // layer: titolo, type: url api, subtypes: array dei tipi esistenti
  const DatidiBase = useMemo(() => {
    if (tipiDominio) {
      return [
        { layer: "Elementi Stradali", type: "elementi-stradali", subtypes: [] },
        { layer: "Giunzioni", type: "giunzioni", subtypes: [] },
        { layer: "Vie", type: "vie", subtypes: [] },
        { layer: "Numeri Civici", type: "civici", subtypes: [] },
        { layer: "Aree", type: "aree", subtypes: tipiDominio.TIPO_AREA_CHOICES ?? [] },
        { layer: "Contenitori", type: "contenitori", subtypes: tipiDominio.TIPO_CONTENITORE_CHOICES ?? [] },
        {
          layer: "Punti di Raccolta",
          type: "punti-raccolta",
          subtypes: tipiDominio.TIPO_PUNTO_RACCOLTA_CHOICES ?? [],
        },
      ]
    }
  }, [tipiDominio])

  return (
    <StandardLayout>
      <div className="sticky-header bg-light p-3 shadow-sm">
        <div className="page-title">Dati di base</div>
      </div>
      <div className="p-3">
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>{"Layer"}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {DatidiBase &&
              DatidiBase?.length > 0 &&
              DatidiBase.map((dato, i) => {
                return (
                  <Fragment key={i}>
                    <tr>
                      <td>{dato.layer}</td>
                      <td>
                        <Link to={`/dati-base/${dato.type}/list`}>
                          <MdList size="24"></MdList>
                        </Link>
                        <Link to={`/dati-base/${dato.type}/map`}>
                          <MdMap size="24"></MdMap>
                        </Link>
                        <MdDownload className="text-primary pointer" onClick={() => downloadGeo(dato.type)} />
                      </td>
                    </tr>
                    {dato.subtypes && dato.subtypes?.length > 0 && (
                      <tr>
                        <td colSpan={2} className="ps-4">
                          <table className="table table-sm mb-1 table-borderless">
                            <tbody>
                              {dato.subtypes.map((sottotipo: string, i: number) => {
                                return (
                                  <tr key={i}>
                                    <td width="45%">{sottotipo}</td>
                                    <td>
                                      <Link to={`/dati-base/${dato.type}/list?tipo=${sottotipo}`}>
                                        <MdList size="24"></MdList>
                                      </Link>
                                      <Link to={`/dati-base/${dato.type}/map?tipo=${sottotipo}`}>
                                        <MdMap size="24"></MdMap>
                                      </Link>
                                      <MdDownload
                                        className="text-primary pointer"
                                        onClick={() => downloadGeo(dato.type, { tipo: sottotipo })}
                                      />
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                )
              })}
          </tbody>
        </table>
      </div>
    </StandardLayout>
  )
}

export default DatiBase
