import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap"
import { mkStringfromDate } from "../helper"
import { useDocumento } from "../hooks/useDocumento"
import { UpdateDocumento } from "../types"
import PopoverConfirm from "./PopoverConfirm"
import PropertyList from "./PropertyList"

const IS_PROD = import.meta.env.PROD

function makeLink(url: string | undefined) {
  if (!url) return ""
  if (IS_PROD) {
    return url.replace("http://", "https://")
  }
  return url
}

type DocumentoModalProps = {
  isOpen: boolean
  toggle?: () => void
  onClosed: () => void
  idDocumento: number
  showEditCommands?: boolean
  onDelete?: (id: number) => void
  onUpdate?: (documento: UpdateDocumento) => Promise<unknown>
}

export default function DocumentoModal({
  isOpen,
  toggle,
  onClosed,
  idDocumento,
  showEditCommands = false,
  onDelete,
  onUpdate,
}: DocumentoModalProps) {
  const documento = useDocumento(idDocumento.toString()).data

  return documento ? (
    <Modal size="lg" show={isOpen} onHide={toggle} onExited={onClosed}>
      <ModalHeader closeButton>{documento.bozza ? "Bozza documento" : "Dettaglio documento"}</ModalHeader>
      <ModalBody>
        <PropertyList
          propLists={[
            [
              ["Tipo dato", documento.tipo_dato_data.nome],
              ["Titolo", documento.titolo],
              ["Inizio periodo", mkStringfromDate(documento.periodo_inizio, "YYYY/MM/DD")],
              ["Fine periodo", mkStringfromDate(documento.periodo_fine, "YYYY/MM/DD")],
              ["Note", documento.note ?? ""],
              [
                "Contenuto",
                <a download href={makeLink(documento.contenuto)}>
                  Download
                </a>,
              ],
              ["Data modifica", mkStringfromDate(documento.data_modifica, "DD/MM/YYYY HH:mm")],
              // ["Codice scheda", schedaServizio.codice_scheda]
            ],
          ]}
        ></PropertyList>
      </ModalBody>
      <ModalFooter className="d-flex flex-row align-items-center justify-content-between">
        {showEditCommands && documento.bozza && documento.is_owner && onDelete && onUpdate && (
          <>
            <PopoverConfirm
              title="Conferma eliminazione bozza"
              buttonClassName="btn btn-sm btn-danger"
              buttonText="Elimina bozza"
              okCallBack={() => onDelete(documento.id)}
              body={"Confermi eliminazione bozza? Questa azione non è reversibile."}
            ></PopoverConfirm>
            <PopoverConfirm
              title="Conferma pubblicazione bozza"
              buttonClassName="btn btn-sm btn-success"
              buttonText="Pubblica bozza"
              okCallBack={() =>
                onUpdate({
                  id: documento.id,
                  periodo_inizio: documento.periodo_inizio,
                  periodo_fine: documento.periodo_fine,
                  bozza: false,
                  titolo: documento.titolo,
                  regola_consegna: documento.regola_consegna,
                })
              }
              body={"Confermi pubblicazione bozza? Questa azione non è reversibile."}
            />
          </>
        )}
      </ModalFooter>
    </Modal>
  ) : null
}
