import { useCallback, useMemo, useRef, useState } from "react"
import { MdZoomIn } from "react-icons/md"

import { fetchApiJson } from "@inmagik/react-crud"
import chunk from "lodash/chunk"
import Feature from "ol/Feature"
import { FeatureLoader } from "ol/featureloader"
import GeoJSON from "ol/format/GeoJSON"
import Geometry from "ol/geom/Geometry"
import { bbox as bboxStrategy } from "ol/loadingstrategy"
import OSM from "ol/source/OSM"
import VectorSource from "ol/source/Vector"
import { useMatch } from "react-router-dom"
import { useAuth } from "../../auth"
import PropertyList from "../../components/PropertyList"
import Search from "../../components/Search"
import StandardLayout from "../../components/StandardLayout"
import { API_URL } from "../../constants"
import { useQsFilters } from "../../hooks/useFilters"
import { Popup } from "../../ol/Popup"
import SimpleMap, { OlMapRef } from "../../ol/SimpleMap"
import { TileLayer } from "../../ol/TileLayer"
import { VectorLayer } from "../../ol/VectorLayer"
import { FeatureCollectionVia, FeatureVia } from "../../types"

const tileSource = new OSM()

const initFilters = (params: URLSearchParams) => ({
  search: params.get("search") ?? "",
  nome_via: params.get("nome_via") ?? "",
})

function FeaturePopup() {
  return (
    <Popup
      autoPan
      renderHtml={(feature, close) => {
        const properties = feature.getProperties()
        const propNames = Object.keys(properties).filter((x) => x !== "geometry")

        const l = Math.floor(propNames.length) / 2
        const groups = chunk(propNames, l)
        const lists = groups.map((group) => group.map((item) => [item, properties[item]])) as [string, string][][]

        return (
          <div className="p-3 bg-white" style={{ width: 600 }}>
            <PropertyList propLists={lists}></PropertyList>
            <hr />
            <button className="btn btn-sm" onClick={close}>
              {"close"}
            </button>
          </div>
        )
      }}
    />
  )
}

// function makeColors(classi: ClasseOggetti[]) {
//   const colors: Record<string, string> = {}
//   classi.forEach((classe, i) => {
//     colors[classe.nome] = schemeTableau10[i]
//   })
//   return colors
// }

// function makeStyle(colors: Record<string, string>) {
//   const styleFun = function (feature: Feature) {
//     const properties = feature.getProperties()
//     const color = colors[properties.nome_classe]

//     return new Style({
//       stroke: new Stroke({
//         color,
//         width: 2,
//       }),
//     })
//   }

//   return styleFun
// }

// export default function DatiBaseMap({ classificazione }: Record<string, Classificazione>) {
export default function DatiBaseMap() {
  const { tokens } = useAuth()
  const { filters, uiFilters, setFiltersDebounced } = useQsFilters(initFilters)
  const [oggettiGeo, setOggettiGeo] = useState<Partial<FeatureVia>[]>()
  const match = useMatch("/dati-base/:type/*")
  const type = match?.params.type

  function makeLoader(
    source: VectorSource<Feature<Geometry>>,
    runFunction: (params: Record<string, string>) => Promise<FeatureCollectionVia>,
    nomeVia: string
  ): FeatureLoader<Feature<Geometry>> {
    return (extent, _resolution, projection) => {
      const proj = projection.getCode()
      const params = {
        // srsname: proj,
        nome_via: nomeVia,
        // search: search,
        bbox: extent.join(",") + "," + proj,
      }

      runFunction(params)
        .then((resp) => {
          setOggettiGeo(resp.features)
          const features = (source.getFormat()?.readFeatures(resp) ?? []) as Feature<Geometry>[]
          source.addFeatures(features)
        })
        .catch(() => source.removeLoadedExtent(extent))
    }
  }

  const Oggetti = useCallback(async () => {
    const response = await fetchApiJson(`${API_URL}/${type}/geo/`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokens?.access}`,
      },
    })
    return response
  }, [tokens?.access, type])

  // const legendColors = useMemo(() => {
  //   if (!classificazione.classi_oggetti_annotate) return null
  //   return makeColors(classificazione.classi_oggetti_annotate)
  // }, [classificazione])

  // const styleFunction = useMemo(() => {
  //   if (!legendColors) return null
  //   return makeStyle(legendColors)
  // }, [legendColors])

  const mapRef = useRef<OlMapRef>(null)

  const source = useMemo(() => {
    const s = new VectorSource({
      wrapX: false,
      url: `/api/${type}/geo/`,
      format: new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }),
      strategy: bboxStrategy,
    })
    const l = makeLoader(s, Oggetti, filters.nome_via)
    s.setLoader(l)
    return s
  }, [type, Oggetti, filters.nome_via])

  const zoomToFeature = useCallback(
    (o: Partial<FeatureVia>) => {
      const feat = source.getFeatureById(o.id!)
      if (feat && mapRef.current) {
        const ext = feat.getGeometry()?.getExtent()
        if (ext) {
          mapRef.current.getMap()?.getView()?.fit(ext, { maxZoom: 18 })
        }
      }
    },
    [source]
  )

  return (
    <StandardLayout>
      <div className="row no-gutters h-100 pt-3">
        <div className="col-sm-9 h-100">
          <SimpleMap ref={mapRef}>
            <TileLayer source={tileSource} />
            <VectorLayer source={source}>
              <FeaturePopup></FeaturePopup>
            </VectorLayer>
          </SimpleMap>
        </div>
        <div className="col-sm-3 p-2 h-100 overflow-auto">
          <Search
            value={uiFilters.nome_via}
            placeholder="Cerca via"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setFiltersDebounced({ nome_via: e.currentTarget.value })
            }
          />
          {uiFilters.nome_via !== "" && oggettiGeo && (
            <table className="table table-striped table-sm">
              <tbody>
                {oggettiGeo.slice(0, 100).map((oggetto: Partial<FeatureVia>, i: number) => {
                  return (
                    <tr key={i}>
                      <td>{oggetto.properties?.toponym}</td>
                      <td>{oggetto.properties?.loc_ref}</td>
                      <td>
                        <MdZoomIn onClick={() => zoomToFeature(oggetto)}></MdZoomIn>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </StandardLayout>
  )
}
