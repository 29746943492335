import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md"
import { PAGE_SIZE } from "../constants"

type PaginatorProps = {
  count: number
  currentPage: number
  goToPage(page: number): void
}

export default function Paginator({ count, currentPage, goToPage }: PaginatorProps) {
  const numPages = Math.ceil(count / PAGE_SIZE)
  return (
    <div className="w-100">
      <div className="row">
        <div className="col-sm-5 text-end">
          {currentPage > 1 && numPages > 0 && (
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={() => {
                goToPage(currentPage - 1)
              }}
            >
              <MdKeyboardArrowLeft />
            </button>
          )}
        </div>
        <div className="col-sm-2 text-center">{`Pagina ${currentPage} di ${Math.max(1, numPages)}`}</div>
        <div className="col-sm-5 text-start">
          {currentPage < numPages && (
            <button
              className="btn btn-sm btn-primary"
              type="button"
              onClick={() => {
                goToPage(currentPage + 1)
              }}
            >
              <MdKeyboardArrowRight />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
