import { useCallback, useMemo, useRef, useState } from "react"
import { MdZoomIn } from "react-icons/md"

import { fetchApiJson } from "@inmagik/react-crud"
import { schemeTableau10 } from "d3-scale-chromatic"
import chunk from "lodash/chunk"
import Feature, { FeatureLike } from "ol/Feature"
import { FeatureLoader } from "ol/featureloader"
import GeoJSON from "ol/format/GeoJSON"
import Geometry from "ol/geom/Geometry"
import { bbox as bboxStrategy } from "ol/loadingstrategy"
import ImageTile from "ol/source/ImageTile"
import VectorSource from "ol/source/Vector"
import { Stroke, Style } from "ol/style"
import { useAuth } from "../../auth"
import PropertyList from "../../components/PropertyList"
import Search from "../../components/Search"
import StandardLayout from "../../components/StandardLayout"
import { API_URL } from "../../constants"
import { useQsFilters } from "../../hooks/useFilters"
import { Popup } from "../../ol/Popup"
import SimpleMap, { OlMapRef } from "../../ol/SimpleMap"
import { TileLayer } from "../../ol/TileLayer"
import { VectorLayer } from "../../ol/VectorLayer"
import { ClasseOggetti, Classificazione, FeatCollectionsClassificazione, FeatureClassificazione } from "../../types"
import ClassificazioneLegend from "./ClassificazioneLegend"
import HeaderClassificazione from "./HeaderClassificazione"
import {BASE_MAP_TILE_URL } from "../../constants"
import { Spinner } from "react-bootstrap"

const tileSource = new ImageTile({
  url: BASE_MAP_TILE_URL,
})

const initFilters = (params: URLSearchParams) => ({
  search: params.get("search") ?? "",
  nome_via: params.get("nome_via") ?? "",
})

function FeaturePopup() {
  return (
    <Popup
      autoPan
      renderHtml={(feature, close) => {
        const properties = feature.getProperties()
        const propNames = Object.keys(properties).filter((x) => x !== "geometry")

        const l = Math.floor(propNames.length) / 2
        const groups = chunk(propNames, l)
        const lists = groups.map((group) => group.map((item) => [item, properties[item]])) as [string, string][][]

        return (
          <div className="p-3 bg-white" style={{ width: 600 }}>
            <PropertyList propLists={lists}></PropertyList>
            <hr />
            <button className="btn btn-sm" onClick={close}>
              {"close"}
            </button>
          </div>
        )
      }}
    ></Popup>
  )
}

function makeColors(classi: ClasseOggetti[]) {
  const colors: Record<string, string> = {}
  classi.forEach((classe, i) => {
    colors[classe.nome] = schemeTableau10[i]
  })
  return colors
}

function makeStyle(colors: Record<string, string>) {
  const styleFun = function (feature: FeatureLike) {
    const properties = feature.getProperties()
    const color = colors[properties.nome_classe]

    return new Style({
      stroke: new Stroke({
        color,
        width: 2,
      }),
    })
  }

  return styleFun
}

export default function ClassificazioneMap({ classificazione }: Record<string, Classificazione>) {
  const { tokens } = useAuth()
  const { filters, uiFilters, setFiltersDebounced } = useQsFilters(initFilters)
  const [oggettiGeo, setOggettiGeo] = useState<Partial<FeatureClassificazione>[]>()
  const [isLoading, setIsLoading] = useState(true)

  function makeLoader(
    source: VectorSource<Feature<Geometry>>,
    runFunction: (params: Record<string, string>) => Promise<FeatCollectionsClassificazione>,
    classificazione: number,
    nomeVia: string,
    callback?: (features: Feature<Geometry>[]) => void
  ): FeatureLoader<Feature<Geometry>> {
    return (extent, _resolution, projection) => {
      const proj = projection.getCode()
      const params = {
        // srsname: proj,
        classificazione: classificazione.toString(),
        nome_via: nomeVia,
        bbox: extent.join(",") + "," + proj,
      }

      runFunction(params)
        .catch(() => source.removeLoadedExtent(extent))
        .then((resp) => {
          if (resp) {
            setOggettiGeo(resp.features)
          } else {
            setOggettiGeo([])
          }
          const features = (source.getFormat()?.readFeatures(resp) ?? []) as Feature<Geometry>[]
          source.addFeatures(features)
          if (callback) {
            callback(features)
          }
        })
    }
  }

  const classificazioniOggetti = useCallback(
    async (values: Record<string, string>) => {
      const query = new URLSearchParams(values)
      const response = await fetchApiJson(`${API_URL}/classificazioni-oggetti/geo/?${query.toString()}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokens?.access}`,
        },
      })
      return response
    },
    [tokens?.access]
  )

  const legendColors = useMemo(() => {
    if (!classificazione.classi_oggetti_annotate) return null
    return makeColors(classificazione.classi_oggetti_annotate)
  }, [classificazione])

  const styleFunction = useMemo(() => {
    if (!legendColors) return null
    return makeStyle(legendColors)
  }, [legendColors])

  const mapRef = useRef<OlMapRef>(null)

  const source = useMemo(() => {
    const s = new VectorSource({
      wrapX: false,
      url: `/api/classificazioni-oggetti/geo/?classificazione=${classificazione.id}`,
      format: new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }),
      strategy: bboxStrategy,
    })
    const l = makeLoader(s, classificazioniOggetti, classificazione.id, filters.nome_via, () => {
      setIsLoading(false)
    })
    s.setLoader(l)

    s.on("featuresloadstart", () => {
      setIsLoading(true)
    })

    return s
  }, [classificazione.id, classificazioniOggetti, filters.nome_via])

  const zoomToFeature = useCallback(
    (o: Partial<FeatureClassificazione>) => {
      const feat = source.getFeatureById(o.id!)
      if (feat && mapRef.current) {
        const ext = feat.getGeometry()?.getExtent()
        if (ext) {
          mapRef.current.getMap()?.getView()?.fit(ext, { maxZoom: 18 })
        }
      }
    },
    [source]
  )
  

  return (
    <StandardLayout>
      {classificazione && <HeaderClassificazione classificazione={classificazione} open="map" />}

      <div className="row no-gutters h-100 pt-3">
        <div className="col-sm-9 h-100 position-relative">
          <SimpleMap ref={mapRef}>
            <TileLayer source={tileSource} />
            
            <VectorLayer zIndex={1} style={styleFunction} source={source}>
              <FeaturePopup></FeaturePopup>
            </VectorLayer>
          </SimpleMap>

          {isLoading && (
        <div
          className="position-absolute top-0 left-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="bg-white p-3 shadow-sm d-flex flex-column justify-content-center align-items-center">
            <Spinner animation="border" className="mb-2" />
            Caricamento in corso
          </div>
        </div>
      )}
        </div>
        <div className="col-sm-3 p-2 h-100 overflow-auto">
          <Search
            value={uiFilters.nome_via}
            placeholder="Cerca via"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setFiltersDebounced({ nome_via: e.currentTarget.value })
            }
          />
          {uiFilters.nome_via !== "" && oggettiGeo && (
            <table className="table table-striped table-sm">
              <tbody>
                {oggettiGeo.slice(0, 100).map((oggetto: Partial<FeatureClassificazione>, i: number) => {
                  return (
                    <tr key={i}>
                      <td>{oggetto.properties?.nome_via}</td>
                      <td>{oggetto.properties?.loc_ref}</td>
                      <td>
                        <MdZoomIn onClick={() => zoomToFeature(oggetto)}></MdZoomIn>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          )}
        </div>
        
      </div>

      <div className="sticky-footer bg-light border-top">
        {classificazione && legendColors && <ClassificazioneLegend colors={legendColors}></ClassificazioneLegend>}
      </div>
    </StandardLayout>
  )
}
