import { fetchApiJson } from "@inmagik/react-crud"
import { API_URL } from "../constants"
import { useAuth } from "../auth"
import { saveAs } from "file-saver"
import { useCallback } from "react"
import { normalizeParams } from "@inmagik/react-crud/dist/crud/utils"

export default function useGeoJSONDownload() {
  const { tokens } = useAuth()

  const download = useCallback(async (type: string, params: Record<string, string | string[] | number[]> = {}) => {
    const qs = normalizeParams(params).toString()
    const response = await fetchApiJson(`${API_URL}/${type}/geo/?${qs}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokens?.access}`,
      },
    })

    const blob = new Blob([JSON.stringify(response)])
    saveAs(blob, `${type}${params.tipo ? `_${params.tipo}` : ""}.geojson`)
  }, [])

  return download
}
