import { Form, Formik } from "formik"
import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { Spinner } from "react-bootstrap"
import GenericFormError from "../../components/Fields/GenericFormError"
import { transformErrorForForm } from "../../components/Fields/utils"
import ModalConfirm from "../../components/ModalConfirm"
import { useModificaRipianificato } from "../../hooks/catalogoservizi"
import { Piano } from "../../types"

const getRipianificatoConfirmText = (pianiBozza: Piano[]) => {
  return (
    <div>
      <p>Proseguendo saranno apportate le seguenti modifiche al piano dei servizi:</p>
      <ul>{pianiBozza && pianiBozza.length > 0 && <li>{pianiBozza.length} piani in bozza saranno attivati</li>}</ul>
    </div>
  )
}

type ModificRipianificatoFormProps = {
  idServizio: number
  pianiBozza?: Piano[]
  refetch: () => void
}

export default function ModificaRipianificatoForm({ idServizio, pianiBozza, refetch }: ModificRipianificatoFormProps) {
  const [confirmModalState, confirmModalActions] = useModalTrigger()
  const { mutateAsync: updateRipianificato } = useModificaRipianificato(idServizio)
  // const { tokens } = useAuth()

  // const updateRipianificato = async () => {
  //   await fetchApi(`${API_URL}/servizi/${idServizio}/modifica_ripianificato/`, {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${tokens?.access}`,
  //       "Content-Type": "application/json",
  //     },
  //   })
  // }

  return (
    <Formik
      initialValues={{}}
      onSubmit={(_values, actions) => {
        return updateRipianificato({})
          .catch((err) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            actions.setErrors(transformErrorForForm(err) as any)
          })
          .then(() => {
            refetch()
            confirmModalActions.toggle()
          })
      }}
    >
      {({ errors, isSubmitting, submitForm }) => (
        <Form>
          <GenericFormError errors={errors}></GenericFormError>

          <div>
            {isSubmitting && <Spinner variant="primary" />}
            {!isSubmitting && (
              <button type="submit" className="btn-sm btn btn-danger" onClick={() => confirmModalActions.open("dummy")}>
                {"Conferma modifiche piano"}
              </button>
            )}
          </div>

          {confirmModalState.value && confirmModalState.isOpen && pianiBozza && (
            <ModalConfirm
              isOpen={confirmModalState.isOpen}
              toggle={confirmModalActions.toggle}
              onClosed={confirmModalActions.onClosed}
              title="Conferma modifica piano"
              body={getRipianificatoConfirmText(pianiBozza)}
              okCallBack={submitForm}
              isSubmitting={isSubmitting}
            />
          )}
        </Form>
      )}
    </Formik>
  )
}
