import { useContext } from "react"
import { useMutation } from "@tanstack/react-query"
import { useCreate, useDelete, useDetail, useList, useUpdate, DataFetchingAuthContext, fetchApiJson,   } from "@inmagik/react-crud"
import { API_URL } from "../constants"
import { CreateDocumento, Documento, UpdateDocumento } from "../types"

export function useDocumentiList(params: Record<string, string | string[] | number[] | number> = {}) {
  return useList<Documento>(`${API_URL}/documenti`, { query: params })
}

export function useDocumento(id: string) {
  return useDetail<Documento>(`${API_URL}/documenti`, id)
}

export function useCreateDocumento() {
  return useCreate<Documento, CreateDocumento>(`${API_URL}/documenti`, { encode: "formdata"})
}

export function useUpdateDocumento() {
  return useUpdate<Documento, UpdateDocumento>(`${API_URL}/documenti`, { encode: "formdata"})
}

export function useDeleteDocumento() {
  return useDelete(`${API_URL}/documenti`)
}


function data2formdata(data: any) {
  const fd = new FormData()
  if (data instanceof File) {
    fd.append("", data)
  } else if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      const itemFD = data2formdata(data[i])
      for (const [key, value] of itemFD.entries()) {
        fd.append(`[${i}]${key}`, value)
      }
    }
  } else if (typeof data === "object" && data !== null) {
    for (const [outerKey, value] of Object.entries(data)) {
      const itemFD = data2formdata(value)
      for (const [key, value] of itemFD.entries()) {
        if (key === "") {
          fd.append(`${outerKey}`, value)
        } else if (key[0] === "[") {
          fd.append(`${outerKey}${key}`, value)
        } else {
          fd.append(`${outerKey}.${key}`, value)
        }
      }
    }
  } else {
    fd.append("", data?.toString() ?? "")
  }
  return fd
}

export function useAggiornaDocumento() {
  const authHeaders = useContext(DataFetchingAuthContext)
  
  
  return useMutation({
    mutationFn: (record: CreateDocumento) => {
      const apiUrl = `${API_URL}/documenti/${record.id}/aggiorna/`
      return fetchApiJson(apiUrl, {
        method: "POST",
        headers: authHeaders,
        body: data2formdata(record),
      })
    },
    
  })
}