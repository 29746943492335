type ClassificazioneLegendProps = {
  colors: Record<string, string>
}

export default function ClassificazioneLegend({ colors = {} }: ClassificazioneLegendProps) {
  const classi = Object.keys(colors)

  return (
    <div className="d-flex flex-row">
      {classi.map((classe, i) => (
        <div key={i} className="d-flex flex-row text-center mx-2 align-items-center justify-content-center">
          <div className="me-1" style={{ width: 10, height: 10, background: colors[classe], margin: 3}} /> {` ${classe}`}
        </div>
      ))}
    </div>
  )
}
