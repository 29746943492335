import { Route, Routes } from "react-router-dom"
import DatiBase from "./DatiBase"
import ElementiStradali from "./ElementiStradali"
import DatiBaseMap from "./DatiBaseMap"
import Giunzioni from "./Giunzioni"
import Vie from "./Vie"
import Civici from "./Civici"
import Aree from "./Aree"
import Contenitori from "./Contenitori"
import ViaDettaglio from "./ViaDettaglio"

export function DatiBaseRoutes() {
  return (
    <Routes>
      <Route path="/" element={<DatiBase />} />
      <Route path="/elementi-stradali/list" element={<ElementiStradali />} />
      <Route path="/elementi-stradali/map" element={<DatiBaseMap />} />
      <Route path="/giunzioni/list" element={<Giunzioni />} />
      <Route path="/giunzioni/map" element={<DatiBaseMap />} />
      <Route path="/vie/list" element={<Vie />} />
      <Route path="/vie/list/:id/:type" element={<ViaDettaglio />} />
      <Route path="/vie/map" element={<DatiBaseMap />} />
      <Route path="/civici/list" element={<Civici />} />
      <Route path="/civici/map" element={<DatiBaseMap />} />
      <Route path="/aree/list" element={<Aree />} />
      <Route path="/aree/map" element={<DatiBaseMap />} />
      <Route path="/punti-raccolta/list" element={<DatiBase />} />
      <Route path="/punti-raccolta/map" element={<DatiBase />} />
      <Route path="/contenitori/list" element={<Contenitori />} />
      <Route path="/contenitori/map" element={<DatiBaseMap />} />
    </Routes>
  )
}
