import { Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../auth"
import { NavMenu } from "./NavMenu"
import { Suspense } from "react"
import { Spinner } from "react-bootstrap"
// import { ToastContainer } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"

type AuthLayoutProps = {
  redirect_to: string
}

export function AuthLayout({ redirect_to }: AuthLayoutProps) {
  const auth = useAuth()

  if (!auth.user) {
    return <Navigate to={redirect_to} replace />
  }

  return (
    <div className="h-100 w-100 with-nav-menu">
      <NavMenu></NavMenu>
      <div className="h-100 w-100">
        <Suspense
          fallback={
            <div className="page d-flex justify-content-center align-items-center">
              <Spinner variant="primary" />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </div>
      {/* <ToastContainer /> */}
    </div>
  )
}
