import { useContext } from "react"
import { OlMapContext } from "./olMapContext"

export function useOlMap() {
  const context = useContext(OlMapContext)
  if (!context) {
    throw new Error("useOlMap must be used within a OlMapProvider")
  }
  return context.olMap
}
