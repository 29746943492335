import { it } from "date-fns/locale/it"
import { registerLocale } from "react-datepicker"
import { mkDatefromString, mkStringfromDate } from "../../helper"

type MyDatePickerType = {
  onChange: (date: Date | null) => void
  startDate?: Date | string
  endDate?: Date | string
  selected?: Date | string
  labelClassName?: string
  labelText?: string
  containerClassName?: string
  containerSelClassName?: string
  selectorClassName?: string
  type?: "standard" | "inizio" | "fine"
  required?: boolean
}

function ensureDate(date: Date | string | null | undefined): Date | null {
  if (date === null || date === undefined) return null
  if (date instanceof Date) return date
  return mkDatefromString(date)
}

export default function MyDatePicker({
  onChange,
  startDate: startDateProp,
  endDate: endDateProp,
  selected: selectedProp,
  type = "standard",
  labelText = "Data",
  containerClassName = "d-flex align-items-center",
  labelClassName = "col-sm-4 col-form-label-sm",
  containerSelClassName = "col-sm-7 d-flex flex-column",
  selectorClassName = "form-control form-control-sm",
  required = true,
}: MyDatePickerType) {
  registerLocale("it", it)

  const startDate: Date | null = ensureDate(startDateProp)
  const endDate: Date | null = ensureDate(endDateProp)
  let selected: Date | null = ensureDate(selectedProp)

  const dateRif = type !== "standard" && type === "inizio" ? startDate : endDate

  if (!selected) {
    if (type === "inizio" && startDate) selected = startDate
    if (type === "fine" && endDate) selected = endDate
  }

  return (
    <div className={containerClassName}>
      <label className={labelClassName}>
        {type === "standard" && labelText === "Data" ? labelText : `${labelText} ${type}`}
      </label>
      <div className={containerSelClassName}>
        <input
          value={mkStringfromDate(selected)}
          className={selectorClassName}
          type="date"
          max={mkStringfromDate(endDate)}
          min={mkStringfromDate(startDate)}
          onChange={(e) => onChange(mkDatefromString(e.target.value))}
          required={required && dateRif ? true : false}
        />
      </div>
    </div>
  )
}
