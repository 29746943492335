import StandardLayout from "../../components/StandardLayout"

const Home = () => {
  return (
    <StandardLayout>
      <div className="p-3">
        <div className="page-title">Homepage</div>
      </div>
    </StandardLayout>
  )
}

export default Home
