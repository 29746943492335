import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "react-bootstrap"

type ModalConfirmProps = {
  isOpen: boolean
  toggle?: () => void
  onClosed: () => void
  okCallBack?: () => void
  buttonClassName?: string
  okButtonClassName?: string
  okButtonText?: string
  cancelButtonClassName?: string
  cancelButtonText?: string
  buttonText?: string
  title?: string
  body?: string | React.ReactNode
  isSubmitting?: boolean
}

export default function ModalConfirm({
  isOpen,
  toggle,
  onClosed,
  okCallBack = () => null,
  okButtonClassName = `btn btn-sm btn-success`,
  okButtonText = "OK",
  cancelButtonClassName = `btn btn-sm btn-default`,
  cancelButtonText = "Cancel",
  title = "",
  body = "",
  isSubmitting,
}: ModalConfirmProps) {
  return (
    <>
      <Modal show={isOpen} onHide={isSubmitting ? undefined : toggle} onExited={onClosed}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter className="d-flex flex-row justify-content-between">
          <button className={okButtonClassName} onClick={toggle} disabled={isSubmitting}>
            {cancelButtonText}
          </button>
          {!isSubmitting && (
            <button
              className={cancelButtonClassName}
              onClick={() => {
                okCallBack()
              }}
            >
              {okButtonText}
            </button>
          )}
          {isSubmitting && <Spinner variant="primary" />}
        </ModalFooter>
      </Modal>
    </>
  )
}
