import { Field, Form, Formik } from "formik"
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "react-bootstrap"
import { FileField } from "../../components/Fields/FileField"
import GenericFormError from "../../components/Fields/GenericFormError"
import { InputField } from "../../components/Fields/InputField"
import { transformErrorForForm } from "../../components/Fields/utils"
import { mkStringfromDate } from "../../helper"
import { CreateDocumento, DocumentoPeriodo, RegolaConsegna } from "../../types"

type DocumentoUploadModalProps = {
  isOpen: boolean
  toggle?: () => void
  onClosed: () => void
  periodo: DocumentoPeriodo
  regolaConsegna: RegolaConsegna
  onSave: (record: CreateDocumento) => Promise<unknown>
}

export default function DocumentiUploadForm({
  isOpen,
  toggle,
  onClosed,
  periodo,
  regolaConsegna,
  onSave,
}: DocumentoUploadModalProps) {
  const constantData = {
    periodo_inizio: periodo.start_date,
    periodo_fine: periodo.end_date,
    regola_consegna: regolaConsegna.id,
    bozza: true,
  }

  function getTitolo(regolaConsegna: RegolaConsegna, periodo: DocumentoPeriodo) {
    if (!regolaConsegna.frequenza_attesa_periodo) {
      return ""
    }
    const start = mkStringfromDate(periodo.start_date, "DD/MM/YYYY")
    const end = mkStringfromDate(periodo.end_date, "DD/MM/YYYY")
    return `${regolaConsegna.tipo_dato_data.nome} (${start} - ${end}) `
  }

  if (!isOpen) return

  return (
    <Formik
      initialValues={{ titolo: getTitolo(regolaConsegna, periodo), note: "", contenuto: undefined }}
      onSubmit={(values, actions) => {
        const uploadValues = {
          ...constantData,
          ...values,
        }
        return onSave(uploadValues).catch((err) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          actions.setErrors(transformErrorForForm(err) as any)
        })
      }}
    >
      {({ handleSubmit, errors, isSubmitting }) => (
        <Modal size="lg" show={isOpen} onHide={toggle} onExited={onClosed}>
          <Form onSubmit={handleSubmit}>
            <ModalHeader className="flex-row justify-content-between bg-primary text-white">
              <span>Upload documento</span>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col sm={6}>
                  <b>Tipo dato:</b> {regolaConsegna.tipo_dato_data.nome}
                </Col>
                <Col sm={6}>
                  <b>Frequenza:</b> {regolaConsegna.frequenza_attesa_periodo}
                  {regolaConsegna.frequenza_attesa_numero && <span> ({regolaConsegna.frequenza_attesa_numero})</span>}
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <b>Scheda:</b> {regolaConsegna.scheda_data ? regolaConsegna.scheda_data.titolo : "-"}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col sm={6}>
                  <b>Inizio periodo</b> {mkStringfromDate(periodo.start_date, "DD/MM/YYYY")}
                </Col>
                <Col sm={6}>
                  <b>Fine periodo</b> {mkStringfromDate(periodo.end_date, "DD/MM/YYYY")}
                </Col>
              </Row>
              <Row></Row>
              <hr />
              <Field
                required
                label={<b>Titolo</b>}
                component={InputField}
                name="titolo"
                readOnly={regolaConsegna.frequenza_attesa_periodo}
                type="text"
              />
              <Field required label={<b>Contenuto</b>} component={FileField} type="file" name="contenuto"></Field>
              <Field component={InputField} label="Note" type="textarea" textarea name="note"></Field>
              <GenericFormError errors={errors}></GenericFormError>
            </ModalBody>
            <ModalFooter>
              {isSubmitting && <Spinner variant="primary" />}
              {!isSubmitting && (
                <button className="btn btn-primary" type="submit">
                  Carica
                </button>
              )}
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}
