import { fetchApi } from "@inmagik/react-crud"
import { Field, Formik, getIn } from "formik"
import { useState } from "react"
import { Button } from "react-bootstrap"
import { useNavigate, useParams } from "react-router-dom"
import * as yup from "yup"
import Footer from "../components/Footer"
import Header from "../components/Header"
import { API_URL } from "../constants"

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Password è un campo obbligatorio")
    .min(8, "La password deve essere lunga almeno 8 caratteri."),
  repeatPassword: yup
    .string()
    .required("Conferma Password è un campo obbligatorio")
    .oneOf([yup.ref("password"), ""], "Le password non corrispondono."),
})

const initialValues = {
  password: "",
  repeatPassword: "",
}

export function ResetPassword() {
  const [confirm, setConfirm] = useState(false)
  const { token } = useParams()
  const navigator = useNavigate()
  return (
    <div>
      <Header />
      <div className="mt-4">
        <div className="row no-gutters">
          <div className="col-md-4 offset-md-4">
            <div className="text-center">
              <div className="page-title">RESET PASSWORD</div>
              <p>Inserisci la nuova password</p>
            </div>
            <Formik
              validationSchema={schema}
              initialValues={initialValues}
              enableReinitialize
              onSubmit={async (values) => {
                await fetchApi(API_URL + "/utenti/users/reset_password/", {
                  method: "POST",
                  body: JSON.stringify({
                    ...values,
                    token,
                  }),

                  headers: {
                    "Content-Type": "application/json",
                  },
                }).then(() => {
                  setConfirm(true)
                })
              }}
            >
              {({ handleSubmit, touched, errors }) => (
                <form onSubmit={handleSubmit} className="p-2 mt-2">
                  <div className="form-group">
                    <Field
                      name="password"
                      type="password"
                      placeholder="password"
                      className={
                        "form-control" + (getIn(errors, "password") && getIn(touched, "password") ? " is-invalid" : "")
                      }
                    />
                    {getIn(errors, "password") && getIn(touched, "password") && (
                      <div className="text-danger">{getIn(errors, "password")}</div>
                    )}
                  </div>
                  <div className="form-group mt-2">
                    <Field
                      name="repeatPassword"
                      type="password"
                      placeholder="repeat password"
                      className={
                        "form-control" +
                        (getIn(errors, "repeatPassword") && getIn(touched, "repeatPassword") ? " is-invalid" : "")
                      }
                    />
                    {getIn(errors, "repeatPassword") && getIn(touched, "repeatPassword") && (
                      <div className="text-danger">{getIn(errors, "repeatPassword")}</div>
                    )}
                  </div>
                  {confirm && <div className="alert alert-success mt-2 py-1">Password modificata con successo.</div>}
                  {!confirm && (
                    <Button type="submit" color="primary" className="w-100 mt-2 text-white">
                      Reset Password
                    </Button>
                  )}
                  {confirm && (
                    <Button color="primary" className="w-100 mt-2 text-white" onClick={() => navigator("/login")}>
                      Vai al Login
                    </Button>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
