import { useMemo } from "react"
import { Typeahead } from "react-bootstrap-typeahead"
import "react-bootstrap-typeahead/css/Typeahead.css"
import { TipoDato } from "../../types"
import { useTipoDatoList } from "./tipodato"

type TipoDatoSelectorProps = {
  onChange: (values: TipoDato[]) => void
  containerClassName?: string
  selectorClassName?: string
  labelClassName?: string
  labelText?: string
  multiple?: boolean
  clearButton?: boolean
  currentFilter: string | string[]
}

const TipoDatoSelector = ({
  onChange,
  containerClassName = "d-flex align-items-center",
  selectorClassName = "col-sm-8",
  labelClassName = "col-sm-4 col-form-label-sm",
  labelText = "Tipo Dato",
  multiple = false,
  clearButton = false,
  currentFilter,
}: TipoDatoSelectorProps) => {
  const tipiDato = useTipoDatoList()
  const currentSelected = useMemo(() => {
    if (currentFilter && tipiDato.data?.results) {
      const selectedIds = Array.isArray(currentFilter) ? currentFilter : [currentFilter]
      return tipiDato.data?.results.filter((item) => selectedIds.indexOf(item.id.toString()) !== -1)
    } else {
      return []
    }
  }, [currentFilter, tipiDato.data?.results])

  return (
    <div className={containerClassName}>
      <label className={labelClassName}>{labelText}</label>
      <Typeahead
        positionFixed
        paginate
        placeholder="Ricerca tipo dato"
        id={"TipoDatoSelector"}
        multiple={multiple}
        clearButton={clearButton}
        isLoading={tipiDato.isLoading}
        labelKey={(option) => `${(option as TipoDato).nome}`}
        options={tipiDato.data?.results || []}
        selected={currentSelected}
        onChange={(values) => onChange(values as TipoDato[])}
        className={selectorClassName}
        size={"sm"}
      />
    </div>
  )
}

export default TipoDatoSelector
