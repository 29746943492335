import useModalTrigger from "magik-react-hooks/useModalTrigger"
import { useCallback, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { useAuth } from "../../auth"
import ModalConfirm from "../../components/ModalConfirm"
import StandardLayout from "../../components/StandardLayout"
import { mkStringfromDate } from "../../helper"
import { useDeletePiano, usePianiList } from "../../hooks/piani"
import { Piano, Scheda } from "../../types"
import HeaderServizi from "./HeaderServizi"
import ModificaPianoForm from "./ModificaPianoForm"
import UploadModal from "./UploadModal"

const filtroPianiAttivi = { attivi: 1, bozza: 0, ripianificato: 0 }
const filtroPianiBozza = { bozza: 1, ripianificato: 0 }

type Props = { schedaServizio: Scheda }

export default function PianoServizio({ schedaServizio }: Props) {
  const { user } = useAuth()
  const idServizio = parseInt(useParams().idServizio!)
  const servizio = schedaServizio.servizi!.find((ser) => ser.id === idServizio)!

  const pianiAttivi = usePianiList(servizio.id, filtroPianiAttivi)
  const pianiBozza = usePianiList(servizio.id, filtroPianiBozza)
  const [deleteModalState, deleteModalActions] = useModalTrigger<Piano>()
  const [updateModalState, updateModalActions] = useModalTrigger()
  const [isModalSubmitting, setIsModalSubmitting] = useState(false)

  const { mutateAsync: deletePiano } = useDeletePiano()

  const refetchPiani = () => {
    pianiAttivi.refetch()
    pianiBozza.refetch()
  }

  const confirmDeletePiano = useCallback(
    (pianoId: number) => {
      setIsModalSubmitting(true)
      return deletePiano(pianoId)
        .then(() => {
          deleteModalActions.close()
          pianiBozza.refetch()
        })
        .finally(() => setIsModalSubmitting(false))
    },
    [deleteModalActions, deletePiano, pianiBozza]
  )

  return (
    <StandardLayout>
      {schedaServizio && servizio && (
        <>
          <HeaderServizi schedaServizio={schedaServizio} servizio={servizio} open="piano" />
          <div className="px-3 pb-3">
            {!user?.is_operatore_comune && (
              <>
                <div className="">
                  <div className="page-title">Piani in bozza</div>

                  {pianiBozza && pianiBozza.data?.length === 0 && <p>Nessun piano in bozza</p>}

                  {pianiBozza && Boolean(pianiBozza.data?.length) && (
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Id</th>
                          <th>Tipo piano</th>
                          <th>Inizio val.</th>
                          <th>Fine val.</th>
                          <th>Quantità tot.</th>
                          <th>Numero vie</th>
                          <th>N. vie su grafo</th>
                          <th>N. oggetti su grafo</th>
                          <th>Azioni</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pianiBozza.data?.map((piano) => (
                          <tr key={piano.id}>
                            <td>
                              <Link to={`/programmazioni/${piano.id}`}>{piano.id}</Link>
                            </td>
                            <td>{piano.ripianificato ? "Ripianificato" : "Pianificato"}</td>
                            <td>{mkStringfromDate(piano.inizio_val, "DD/MM/YYYY")}</td>
                            <td>{mkStringfromDate(piano.fine_val, "DD/MM/YYYY")}</td>
                            <td>{piano.totale_lavorazioni_piano?.quantita ?? ""}</td>
                            <td>{piano.totale_lavorazioni_piano?.numero_vie ?? ""}</td>
                            <td>{piano.totale_lavorazioni_piano?.numero_vie_agganciate ?? ""}</td>
                            <td>{piano.totale_lavorazioni_piano?.numero_oggetti ?? ""}</td>
                            <td>
                              <button
                                className="btn btn-warning btn-sm"
                                onClick={() => deleteModalActions.open(piano)}
                                type="button"
                              >
                                {"Elimina"}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}

                  <div>
                    <button className="btn btn-sm btn-primary" onClick={() => updateModalActions.open("dummy")}>
                      Carica bozza piano
                    </button>
                  </div>
                </div>

                <hr />
              </>
            )}

            <div className="page-title">Piani attivi</div>

            {pianiAttivi && pianiAttivi.data?.length === 0 && <p>Nessun piano attivo</p>}

            {servizio && (
              <ModificaPianoForm
                pianiAttivi={pianiAttivi.data}
                pianiBozza={pianiBozza.data}
                idServizio={servizio.id}
                refetch={() => refetchPiani()}
              />
            )}
          </div>
        </>
      )}

      {deleteModalState.value && (
        <ModalConfirm
          isOpen={deleteModalState.isOpen}
          toggle={deleteModalActions.toggle}
          onClosed={deleteModalActions.onClosed}
          title="Elimina bozza piano"
          body="Confermi l'eliminazione del piano in bozza?"
          okCallBack={() => confirmDeletePiano(deleteModalState.value!.id)}
          isSubmitting={isModalSubmitting}
        />
      )}

      {updateModalState.value && (
        <UploadModal
          isOpen={updateModalState.isOpen}
          toggle={updateModalActions.toggle}
          onClosed={updateModalActions.onClosed}
          idServizio={servizio.id}
          typeLoad="caricamento_pds"
          fineValRequired
          onUpload={refetchPiani}
        />
      )}
    </StandardLayout>
  )
}
