import { useAction, useCreate, useDelete, useDetail, useList, usePartialUpdate, useUpdate } from "@inmagik/react-crud"
import { API_URL } from "../constants"
import { UserCreate, UserUpdate } from "../pages/Utenti/types"
import { User } from "../types"

export function useUtentiList(params: Record<string, string> = {}) {
  return useList<User>(`${API_URL}/utenti/users`, { query: params })
}

export function useUtente(id: string) {
  return useDetail<User>(`${API_URL}/utenti/users`, id)
}

export function useCreateUtente() {
  return useCreate<User, UserCreate>(`${API_URL}/utenti/users`)
}

export function useUpdateUtente() {
  return useUpdate<User, UserUpdate>(`${API_URL}/utenti/users`)
}

export function useDeleteUtente() {
  return useDelete(`${API_URL}/utenti/users`)
}

export function usePartialUpdateUtente() {
  return usePartialUpdate<User, UserUpdate>(`${API_URL}/utenti/users`)
}

export function useResendMailUtente(id: number | undefined) {
  return useAction(`${API_URL}/utenti/users/${id}/resend_activation_email`)
}
export function useResendMailMultipleUtente() {
  return useAction(`${API_URL}/utenti/users/resend_activation_email_multiple`)
}
export function useDeleteMultipleUtente() {
  return useAction(`${API_URL}/utenti/users/delete_multiple`, { responseType: "raw" })
}
