import { Link } from "react-router-dom"
import PropertyList from "../../components/PropertyList"
import { Scheda, Servizio } from "../../types"

type HeaderServiziProps = {
  schedaServizio: Scheda
  servizio: Servizio
  open: "info" | "piano" | "vne" | "extralavorazioni"
}

export default function HeaderServizi({ schedaServizio, servizio, open }: HeaderServiziProps) {
  return (
    <>
      <div className="sticky-header shadow-sm p-3 bg-white">
        <div className="fs-20">
          <Link to={`/catalogo-servizi/schede/${schedaServizio.id}`}>
            {`${schedaServizio.titolo} - ${schedaServizio.codice_scheda}`}
          </Link>
          {` / ${servizio.descrizione}`}
        </div>
        <div>
          <Link
            className={`btn btn-sm ${open === "info" ? "btn-primary" : "btn-default"} me-2`}
            to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}`}
            state={{ schedaServizio, servizio }}
          >
            Informazioni
          </Link>
          <Link
            className={`btn btn-sm ${open === "piano" ? "btn-primary" : "btn-default"} me-2`}
            to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}/piano`}
            state={{ schedaServizio, servizio }}
          >
            Piano del servizio
          </Link>
          <Link
            className={`btn btn-sm ${open === "vne" ? "btn-primary" : "btn-default"} me-2`}
            to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}/vne`}
            state={{ schedaServizio, servizio }}
          >
            Non eseguito
          </Link>
          <Link
            className={`btn btn-sm ${open === "extralavorazioni" ? "btn-primary" : "btn-default"} me-2`}
            to={`/catalogo-servizi/schede/${schedaServizio.id}/servizio/${servizio.id}/extralavorazioni`}
            state={{ schedaServizio, servizio }}
          >
            Extra lavorazioni
          </Link>
        </div>
      </div>
      <div className="pt-3 px-3">
        <div className="mt-2">
          <PropertyList
            propLists={[
              [
                ["Codice servizio", servizio.codice_servizio],
                ["Descrizione servizio", servizio.descrizione],
                ["Codice scheda", schedaServizio.codice_scheda],
              ],
              [
                ["Tipo servizio", schedaServizio.tipo_servizio],
                ["Periodicità report", schedaServizio.periodicita_report],
                ["Unità di misura", schedaServizio.um],
              ],
            ]}
          />
        </div>
        <hr />
      </div>
    </>
  )
}
