import { Spinner } from "react-bootstrap"
import { Route, Routes, useParams } from "react-router-dom"
import StandardLayout from "../../components/StandardLayout"
import { useCatalogoServizio } from "../../hooks/catalogoservizi"
import CaricamentoVNE from "./CaricamentoVNE"
import DettaglioServizio from "./DettaglioServizio"
import PianoServizio from "./PianoServizio"
import RipianificatoServizio from "./RipianificatoServizio"
import SchedaServizioDettaglio from "./SchedaServizioDettaglio"

export function SchedaServizioRoutes() {
  const schedaServizioId = useParams().id
  const schedaServizio = useCatalogoServizio(schedaServizioId!.toString()).data

  if (!schedaServizio) {
    return (
      <StandardLayout>
        <div className="h-100 w-100 d-flex align-items-center justify-content-center primary">
          <Spinner variant="primary" />
        </div>
      </StandardLayout>
    )
  }

  return (
    <Routes>
      <Route index element={<SchedaServizioDettaglio schedaServizio={schedaServizio} />} />
      <Route path="servizio/:idServizio" element={<DettaglioServizio schedaServizio={schedaServizio} />} />
      <Route path="servizio/:idServizio/piano" element={<PianoServizio schedaServizio={schedaServizio} />} />
      <Route path="servizio/:idServizio/vne" element={<CaricamentoVNE schedaServizio={schedaServizio} />} />
      <Route
        path="servizio/:idServizio/extralavorazioni"
        element={<RipianificatoServizio schedaServizio={schedaServizio} />}
      />
    </Routes>
  )
}
