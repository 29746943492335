import { fetchApiJson } from "@inmagik/react-crud"
import chunk from "lodash/chunk"
import Feature from "ol/Feature"
import { FeatureLoader } from "ol/featureloader"
import GeoJSON from "ol/format/GeoJSON"
import Geometry from "ol/geom/Geometry"
import { bbox as bboxStrategy } from "ol/loadingstrategy"
import { OSM } from "ol/source"
import VectorSource from "ol/source/Vector"
import { useCallback, useMemo, useRef } from "react"
import { useMatch } from "react-router-dom"
import { useAuth } from "../../auth"
import PropertyList from "../../components/PropertyList"
import { API_URL } from "../../constants"
import { Popup } from "../../ol/Popup"
import { Stroke, Style } from "ol/style"
import SimpleMap, { OlMapRef } from "../../ol/SimpleMap"
import { TileLayer } from "../../ol/TileLayer"
import { VectorLayer } from "../../ol/VectorLayer"
import { FeatureCollectionVia } from "../../types"

const tileSource = new OSM()

function FeaturePopup() {
  return (
    <Popup
      autoPan
      renderHtml={(feature, close) => {
        const properties = feature.getProperties()
        const propNames = Object.keys(properties).filter((x) => x !== "geometry")

        const l = Math.floor(propNames.length) / 2
        const groups = chunk(propNames, l)
        const lists = groups.map((group) => group.map((item) => [item, properties[item]])) as [string, string][][]

        return (
          <div className="p-3 bg-white" style={{ width: 600 }}>
            <PropertyList propLists={lists}></PropertyList>
            <hr />
            <button className="btn btn-sm" onClick={close}>
              {"close"}
            </button>
          </div>
        )
      }}
    />
  )
}

export function MyMap({ streetcode }: { streetcode: string }) {
  const { tokens } = useAuth()
  const match = useMatch("/dati-base/:type/*")
  const type = match?.params.type
  function makeLoader(
    source: VectorSource<Feature<Geometry>>,
    runFunction: (params: Record<string, string>) => Promise<FeatureCollectionVia>
  ): FeatureLoader<Feature<Geometry>> {
    return (extent, _resolution, projection) => {
      const proj = projection.getCode()
      const params = {
        // srsname: proj,
        // search: search,
        bbox: extent.join(",") + "," + proj,
      }

      runFunction(params)
        .then((resp) => {
          const features = (source.getFormat()?.readFeatures(resp) ?? []) as Feature<Geometry>[]
          source.addFeatures(features)
          if (features) {
            const ext = features[0].getGeometry()?.getExtent()
            if (ext && mapRef.current) {
              mapRef.current.getMap()?.getView()?.fit(ext, { maxZoom: 16 })
            }
          }
        })
        .catch(() => source.removeLoadedExtent(extent))
    }
  }

  const Oggetti = useCallback(async () => {
    const response = await fetchApiJson(`${API_URL}/${type}/geo/?streetcode=${streetcode}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tokens?.access}`,
      },
    })
    return response
  }, [tokens?.access, type])

  const mapRef = useRef<OlMapRef>(null)

  const source = useMemo(() => {
    const s = new VectorSource({
      wrapX: false,
      url: `${API_URL}/${type}/geo/?streetcode=${streetcode}`,
      format: new GeoJSON({
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }),
      strategy: bboxStrategy,
    })
    const l = makeLoader(s, Oggetti)
    s.setLoader(l)
    return s
  }, [type, Oggetti])

  return (
    <SimpleMap ref={mapRef}>
      <TileLayer source={tileSource} />
      <VectorLayer
        zIndex={1}
        source={source}
        style={() => {
          return new Style({
            stroke: new Stroke({
              color: "dodgerblue",
              width: 3,
            }),
          })
        }}
      >
        <FeaturePopup></FeaturePopup>
      </VectorLayer>
    </SimpleMap>
  )
}
