import { Feature } from "ol"
import Overlay from "ol/Overlay"
import { Geometry } from "ol/geom"
import Layer from "ol/layer/Layer"
import React, { useEffect, useRef, useState } from "react"
import { useOlMap } from "./context/useOlMap"
import { useOlVectorLayer } from "./context/useOlVectorLayer"
import { unByKey } from "ol/Observable"

type Props = {
  renderHtml: (feature: Feature<Geometry>, closePopup: () => void) => React.ReactNode
  autoPan: boolean
  trigger?: "click" | "pointermove"
}

export function Popup({ renderHtml, autoPan, trigger="click" }: Props) {
  const olMap = useOlMap()
  const olLayer = useOlVectorLayer()
  const popupContainer = useRef<HTMLDivElement>(null)
  const [currentFeature, setCurrentFeature] = useState<Feature<Geometry> | null>(null)
  const overlay = useRef<Overlay | null>(null)

  useEffect(() => {
    if (!overlay.current) {
      overlay.current = new Overlay({
        element: popupContainer.current!,
        positioning: "bottom-center",
        offset: [0, -10],
      })
      olMap.addOverlay(overlay.current)
      // overlay.current.getElement()?.addEventListener("click", () => {
      //   overlay.current?.setPosition(undefined)
      // })
    }
  }, [autoPan, olMap])

  useEffect(() => {
    const key = olMap.on(trigger, (e) => {
      const features = e.target.getFeaturesAtPixel(e.pixel, {
        layerFilter: (candidateLayer: Layer) => candidateLayer === olLayer,
      })

      if (features && features.length) {
        const currentFeature = features[0]
        setCurrentFeature(currentFeature)
        overlay.current?.setPosition(e.coordinate)
      } else if (trigger === "pointermove") {
        setCurrentFeature(null)
      }
    })
    return () => {
      unByKey(key)
    }
  }, [olMap, olLayer, trigger])

  return (
    <div className="arrow_box popup-container" ref={popupContainer}>
      {renderHtml &&
        currentFeature &&
        renderHtml(currentFeature, () => {
          setCurrentFeature(null)
        })}
    </div>
  )
}
